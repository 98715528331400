import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import { Button, } from '@material-ui/core'
import { CustomCheckBox, CustomSelect, CustomSwitch, CustomMultiselectChip } from '../layout/GlobalStyles'
import { DomainsItems } from '../../tools'
import Grid from '@material-ui/core/Grid'
import { InputLabel } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: '100%',
			height: theme.spacing(10),
		},
	},

	labels: {
		fontSize: '1.1em',
		//fontWeight: 'bold',
		// color: '#288DB1',
		color: 'black',
	},

	chip: {
		color: '#fff',
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		'&:active': {
			background: '#1c627b'
		},
		'&:focus': {
			background: '#288DB1',
			color: '#fff'
		},
		// '&:disabled':{
		// 	background:'#1c627b' ,
		// 	color:'#fff'
		// }
	},
	switchSelectAll: {
		position: "relative",
	},
})

const modeMedia = [
	{ id: 0, name: 'Défaut' },
	{ id: 1, name: 'BA Uniquement' },
	{ id: 2, name: 'Ignorer BA' },
	{ id: 3, name: 'Ajouter BA' },
	{ id: 4, name: 'Disponibilté média' }
]

const imageBddpOption = [
	{ id: 0, name: 'Tout' },
	{ id: 1, name: 'Album uniquement' },
	{ id: 2, name: 'Programmes uniquement' }
]
const cibles = [
	{ id: 0, name: 'Toutes' },
	{ id: 1, name: 'Bouygues' },
	{ id: 2, name: 'FREE' },
	{ id: 3, name: 'SFR' },
	{ id: 4, name: 'Orange (uniquement Adulte)' },
	{ id: 5, name: 'Apple Devices (HLS MKPC)' },
	{ id: 6, name: 'Web D2G (HSS)' },
	{ id: 7, name: 'Web/G9/G10/G11/Android/SmartTV/Console (DASH MKPC)' },
	{ id: 8, name: 'G5R7/G6 (HLS Nagra)' },
	{ id: 9, name: 'GLine PDL (TS)' }

]

const onChangeRules = (event, infos) => e => {
	let value
	switch (event) {
		case 'target':
			value = e.substring(0, e.length - 1)
			this.setState({
				[event]: value
			}, () => {
				this.updateState()
			})

			break


	}
}
const JobsComponent = (props) => {
	const { classes, job, listcheckedJobs, album, onExportJobs, ExportJobs } = props
	//const [selectedAlbum,setAlbum] = useState({})
	///sfr job
	const [sfrAlbum, setsfrAlbum] = useState(0)
	// const [selectsfrdata, setsfrdata] = useState(0)
	const [loading, setLoading] = useState(true)
	//
	const [mediahubMode, setMediahubMode] = useState(0)
	const [cablelabsHapi, setCablelabsHapi] = useState(album.isPack ? 1 : 2)
	const [albumImageBDDP, setAlbumImageBDDP] = useState(1)
	const [choices, setChoices] = React.useState([])
	const [selectedMediaCible, setSelectedMediaCible] = useState(cibles.filter(s => s.id === 0))
	const [imagePivot, setImagePivot] = useState([])
	const [albumMovieList, setAlbumMovieList] = useState([])
	const [imgAlbumTypes, setImgAlbumTypes] = useState([])
	const [bytelAlbumMovies, setBytelAlbumMovies] = useState(false)
	const [allPivot, setAllPivot] = useState(true)
	const [allcibles, setAllCibles] = useState(true)
	const [isUHD, setIsUHD] = useState(false)
	const [isDisableDolby, setDisableDolby] = useState(false)
	const [isDisableAudioDes, setDisableAudioDes] = useState(false)

	const sfrList = [
		{ id: 0, name: album.typeid === 2 ? 'Pack uniquement' : album.typeid === 4 ? 'Série uniquement' : album.typeid === 5 ? 'Saison uniquement' : 'Album' },
		{ id: 1, name: album.typeid === 4 || album.typeid === 5 ? 'Toutes les saisons' : 'N/A' },
		{ id: 2, name: album.typeid === 4 || album.typeid === 5 ? 'Série + saisons' : 'Pack + Programmes' },
		{ id: 3, name: album.typeid === 5 || album.typeid === 2 ? 'Programmes uniquement' : 'N/A' }
	]
	const cableabsOption = [
		{ id: 0, name: album.isPack ? 'Tout' : 'N/A' },
		{ id: 1, name: album.isPack ? 'Infos Pack uniquement' : 'N/A' },
		{ id: 2, name: 'Programmes uniquement' }
	]
	const handleMediaCibleClick = (element, newvalue) => {
		console.log(element.target.value)
		console.log(newvalue)
		let checkedCibles = [...selectedMediaCible];

		if (newvalue.find(s => s.id === 0) || newvalue.length === cibles.length - 1)
			checkedCibles = cibles.filter(s => s.id === 0)
		else
			checkedCibles = newvalue
		// if (!checkedCibles.find(s=>s.id === cible.id )) {
		// 	checkedCibles.push(cibles.filter(s=>s.id === cible.id)[0])
		//    // Ajoute l'élément s'il est coché
		// }else if ( checkedCibles.find(s=>s.id === cible.id )) {
		// 	checkedCibles = checkedCibles.filter(s=>s.id !== cible.id) // Retire l'élément s'il est décoché
		// }	  
		// if(checkedCibles.length === cibles.length) setAllCibles(true)
		// 	else setAllCibles(false)
		// Mettre à jour l'état avec la nouvelle liste
		setSelectedMediaCible(checkedCibles)
		// setSelectedMediaCible((prevSelected) =>
		// 	prevSelected.includes(label)
		// 		? prevSelected.filter((chip) => chip !== label)
		// 		: [...prevSelected, label]
		// )
	}

	const AddButton = withStyles((theme) => ({
		root: {
			color: 'white',
			backgroundColor: '#288DB1',
			'&:hover': {
				backgroundColor: '#1C627B',
			},
		},
	}))(Button)


	useEffect(() => {
		if (listcheckedJobs.find(s => s === 12)) {
			//setJobsProperties(selectsfrdata,'sfrData')	
			setJobsProperties(sfrAlbum, 'sfrAlbumType')
		}
		// if(listcheckedJobs.find(s=>s === job.itemid)){
		// 	setJobsProperties(0,'Other')	
		// }
		if (listcheckedJobs.find(s => s === 15)) {
			setJobsProperties(15, 'MediaHub')
		}
		if (listcheckedJobs.find(s => s === 29)) {
			setJobsProperties(cablelabsHapi, 'cablelabsHapi')
		}
		if (job.itemid === 30) {
			setImagePivot(Object.values(DomainsItems.getItems(8).filter(s => !s.disabled && s.isPivot && s.itemid !== 10)))
			setImgAlbumTypes(Object.values(DomainsItems.getItems(8).filter(s => !s.disabled && s.isPivot && s.itemid !== 10)))

		}
		if (job.itemid === 4 && listcheckedJobs.find(s => s === 4)) {
			setJobsProperties(4, 'bytelAlbumMovies')
		}


	}, [listcheckedJobs])

	useEffect(() => {
		if (listcheckedJobs.find(s => s === 4)) {
			setJobsProperties(4, 'bytelAlbumMovies')
		}
	}, [bytelAlbumMovies])
	useEffect(() => {
		console.log('test useeffect 15')
		if (listcheckedJobs.find(s => s === 15)) {
			setJobsProperties(15, 'MediaHub')
		}
	}, [selectedMediaCible, mediahubMode, isUHD, isDisableDolby, isDisableAudioDes])
	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);  // Après 2 secondes, désactivez l'état de chargement
		}, 2000)

		return () => clearTimeout(timer);  // Nettoyage du timer si le composant est démonté
	}, [])

	// Utilisez useEffect pour appeler setJobsProperties après la mise à jour de imgAlbumTypes
	useEffect(() => {
		if (imgAlbumTypes.length > 0) { // Vous pouvez ajuster la condition selon vos besoins
			setJobsProperties(albumImageBDDP, 'BddpAlbum');
		}
		else {
			let listjobs = ExportJobs.filter(s => s.typeid !== 30 && s.typeid !== 27);
			onExportJobs(listjobs)
		}
	}, [imgAlbumTypes, albumImageBDDP])  // Surveillez imgAlbumTypes et albumImageBDDP


	const onChangeImagetype = (e, checked, index) => {
		// Créez une copie de imgAlbumTypes pour éviter de muter directement l'état
		let checkedList = [...imgAlbumTypes];
		if (!checkedList.find(s => s.itemid === index)) {
			checkedList.push(imagePivot.filter(s => s.itemid == index)[0])
			// Ajoute l'élément s'il est coché
		} else if (checkedList.find(s => s.itemid === index)) {
			checkedList = checkedList.filter(s => s.itemid !== index); // Retire l'élément s'il est décoché
		}
		if (checkedList.length === imagePivot.length) setAllPivot(true)
		else setAllPivot(false)
		// Mettre à jour l'état avec la nouvelle liste
		setImgAlbumTypes(checkedList)
	}

	const onSwitchImagePivot = (e, checked) => {
		setAllPivot(checked)
		setImgAlbumTypes(checked ? imagePivot : [])
	}

	const onSwitchAllCible = (e, checked) => {
		setAllCibles(checked)
		setSelectedMediaCible(checked ? cibles : [])
	}
	const setJobsProperties = async (id, propList) => {
		let listjobs = ExportJobs
		//switch (job.itemid){
		let date = new Date()
		switch (job.itemid) {

			case 4: {
				//setBytelAlbumMovies(!bytelAlbumMovies)
				//if(propList==='bytelAlbumMovies' )
				if (propList === 'bytelAlbumMovies') {
					let listjobs = ExportJobs.filter(s => s.typeid !== 4)
					let newjob = {
						typeid: job.itemid,
						refid: album.id,
						executiondate: date.toLocaleString("en-US"),
						displayAllMovie: bytelAlbumMovies
					}
					listjobs.push(newjob)
					onExportJobs(listjobs)
				}
			}

			case 12: {


				if (propList === 'sfrAlbumType') {
					// listjobs = listjobs.filter(s=>s.typeid !== 12)
					let sfrJob = ExportJobs.filter(s => s.typeid === 12) && ExportJobs.filter(s => s.typeid === 12)[0]
					let list = ExportJobs.filter(s => s.typeid !== 12)

					if (!sfrJob || sfrJob.length === 0)
						sfrJob = { typeid: job.itemid, refid: album.id, xml: 1, picture: 1, sfralbumType: sfrAlbum }
					sfrJob.xml = 1
					sfrJob.picture = 1
					sfrJob.sfralbumType = id
					setsfrAlbum(id)
					list.push(sfrJob)
					onExportJobs(list)
				}

			}
			case 15: {


				if (propList === 'MediaHub') {
					// listjobs = listjobs.filter(s=>s.typeid !== 12)
					let mediaHub = ExportJobs.filter(s => s.typeid === 15) && ExportJobs.filter(s => s.typeid === 15)[0]
					let list = ExportJobs.filter(s => s.typeid !== 15)


					if (!mediaHub || mediaHub.length === 0)
						mediaHub = {
							typeid: job.itemid,
							refid: album.id,
							mediaHubMode: mediahubMode,
							cibles: selectedMediaCible.length === cibles.length ? "" : selectedMediaCible.join('|'),
							isUHD: isUHD,
							disableDolby: isDisableDolby,
							disableAudioDes: isDisableAudioDes
						}
					else {
						mediaHub.mediaHubMode = mediahubMode
						mediaHub.cibles = selectedMediaCible.length === cibles.length ? "" : selectedMediaCible.map(s => s.name).join('|')
						mediaHub.isUHD = isUHD
						mediaHub.disableDolby = isDisableDolby
						mediaHub.disableAudioDes = isDisableAudioDes
					}
					list.push(mediaHub)
					onExportJobs(list)
				}

			}
			case 29: {

				if (propList === 'cablelabsHapi') {
					setCablelabsHapi(id)
					let albumHAPIJob = ExportJobs.filter(s => s.typeid === 29) && ExportJobs.filter(s => s.typeid === 29)[0]
					let list = ExportJobs.filter(s => s.typeid !== 29)
					if (!albumHAPIJob || albumHAPIJob.length === 0)
						albumHAPIJob = { typeid: job.itemid, refid: album.id, albumHAPI: id }
					albumHAPIJob.albumHAPI = id

					list.push(albumHAPIJob)
					onExportJobs(list)
				}

			}
			case 30: {

				if (propList === 'BddpAlbum' && listcheckedJobs.find(s => s === 30)) {
					setAlbumImageBDDP(id)
					let bddpalbumJob = ExportJobs.filter(s => s.typeid === 30) && ExportJobs.filter(s => s.typeid === 30)[0]
					let list = ExportJobs.filter(s => s.typeid !== 30)

					if (!bddpalbumJob || bddpalbumJob.length === 0)
						bddpalbumJob = { typeid: job.itemid, refid: album.id }
					if (imgAlbumTypes.length !== 0)
						bddpalbumJob.imgtype = imgAlbumTypes.map(item => item.itemid).join(';')
					else return
					bddpalbumJob.bddpAlbum = id
					list.push(bddpalbumJob)
					onExportJobs(list)
				}
			}

		}
	}

	switch (job.itemid) {
		case 0: {
			if (loading)
				return <CircularProgress />
		}
		case 4:
			return (
				<div>
					<CustomCheckBox label="Programmes uniquement" disabled={!listcheckedJobs.find(s => s === 4) || album.typeid === 4} labelPlacement='end' style={{ marginBottom: '0px' }} onChange={() => setBytelAlbumMovies(!bytelAlbumMovies)} />
					<AddButton variant="contained" size="small" disabled={!listcheckedJobs.find(s => s === 4) || album.typeid === 5 || true} style={{ marginLeft: '120px', marginRight: '10px', marginBottom: '0px' }}>
						Voir les statut Bouygues (Ingrid)
					</AddButton>
				</div>
			)
			break
		case 5:
			return (
				<div>

					<AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
						test 5
					</AddButton>
				</div>
			)
			break
		case 7:
			return (
				<div>

					<AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
						test 7
					</AddButton>
				</div>
			)
			break
		case 12:
			return (
				<div>
					<Grid container>
						<Grid item xs='4px' >
							<CustomSelect values={sfrList.filter(s => s.name !== 'N/A')} value={sfrAlbum} handleChange={(e) => setJobsProperties(e.target.value, 'sfrAlbumType')} disabled={!listcheckedJobs.find(s => s === 12)} />

						</Grid>
						{/* <Grid item xs='4px' >
							<CustomSelect values={sfrData} value={selectsfrdata} handleChange={(e) =>setJobsProperties(e.target.value,'sfrData') } disabled={!listcheckedJobs.find(s=>s ===12)} />
						</Grid> */}
					</Grid>
				</div>
			)
			break
		case 15:
			return (
				<div>

					<Grid container>


						<Grid item xs='6px' style={{ margin: '.5em' }}>

							<CustomMultiselectChip values={cibles} value={selectedMediaCible}
								handleChange={(e, newvalue) => handleMediaCibleClick(e, newvalue)}
								style={{ background: '#288DB1', margin: '.5em' }} label="Cibles"
								clickable disabled={!listcheckedJobs.find(s => s === 15)} />
						</Grid>
						<Grid item xs='6px' >
							<CustomSelect values={modeMedia} label='Mode' disabled={!listcheckedJobs.find(s => s === 15)} value={mediahubMode} handleChange={e => setMediahubMode(e.target.value)} />
						</Grid>

					</Grid>
					{/* <Grid item xs='3px' > */}
					{/* </Grid> */}
					<Grid container>

						<Grid item xs='3px' >
							<CustomCheckBox label="Forcer en HD?" disabled={!listcheckedJobs.find(s => s === 15)} labelPlacement='end' style={{ marginBottom: '0px' }} onChange={() => setIsUHD(!isUHD)} />
						</Grid>
						<Grid item xs='3px' >
							<CustomCheckBox label="Désactiver le Dolby?" disabled={!listcheckedJobs.find(s => s === 15)} labelPlacement='end' style={{ marginBottom: '0px' }} onChange={() => setDisableDolby(!isDisableDolby)} />
						</Grid>
						<Grid item xs='3px' >
							<CustomCheckBox label="Désactiver l'audiodescription?" disabled={!listcheckedJobs.find(s => s === 15)} labelPlacement='end' style={{ marginBottom: '0px' }} onChange={() => setDisableAudioDes(!isDisableAudioDes)} />
						</Grid>
					</Grid>
				</div>
			)
			break
		case 29:
			return (
				<div>
					<CustomSelect values={cableabsOption.filter(s => s.name !== 'N/A')} disabled={!listcheckedJobs.find(s => s === 29)} value={cablelabsHapi} handleChange={e => setJobsProperties(e.target.value, 'cablelabsHapi')} />

				</div>
			)
			break
		case 30:
			return (
				<div>
					<Grid container>
						<Grid item xs='4px' >
							<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Options d'export image BDDP:</InputLabel>
						</Grid>
						<Grid item xs='4px' >

							<Tooltip title={allPivot ? "Désélectionner tout" : "Sélectionner tout"}
								placement="right-end" >
								<CustomSwitch
									checked={allPivot}
									onChange={(e, checked) => onSwitchImagePivot(e, checked)}
									color="primary" size="small"
									inputProps={{ 'aria-label': 'primary checkbox' }}
									disabled={!listcheckedJobs.find(s => s === 30)}
									className={classes.switchSelectAll}
								/>
							</Tooltip>
						</Grid>

					</Grid>

					<Grid item xs='6px' style={{ margin: '.5em' }}>
						{imagePivot.map((element, index) => (
							<Tooltip key={index} title={element.label} style={{ backgroundColor: "#000", margin: '1.5em' }}>
								<Chip className={classes.chip} clickable disabled={!listcheckedJobs.find(s => s === 30)}
									label={DomainsItems.getFormattedLabel(element.domainid, element.itemid)}
									style={{ background: imgAlbumTypes.find(s => s.itemid === element.itemid) ? '#288DB1' : 'lightgrey', margin: '.5em' }}
									onClick={(e, checked) => onChangeImagetype(e, checked, element.itemid)}
								/>
							</Tooltip>
						))}
					</Grid>


					{/* <CustomCheckBox label="Jaquette Standard (13)"  disabled={!listcheckedJobs.find(s=>s ===30)} labelPlacement='end' color="#000" onChange={(e, checked)=>onChangeImagetype(checked,13)}/>
					<CustomCheckBox label="16/9 (239)"    disabled={!listcheckedJobs.find(s=>s ===30)} labelPlacement='end' color="#000" onChange={(e, checked)=>onChangeImagetype(checked,239)}/> */}
					<div>
						<CustomSelect values={imageBddpOption} value={albumImageBDDP} handleChange={e => setJobsProperties(e.target.value, 'BddpAlbum')} disabled={!listcheckedJobs.find(s => s === 30)} />
					</div>
				</div>

			)

			break
		case 37:
			return (
				<div>

					{/* <AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
						test 37
					</AddButton> */}
				</div>
			)
			break

		default:
			return (<div></div>)
			break
	}

}

const enhance = compose(
	withStyles(styles)
)

export default enhance(JobsComponent)

