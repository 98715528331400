import React, { Component } from 'react'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomTextField, CustomDisplayDialog, CustomSelect, CustomCheckBox } from '../../layout/GlobalStyles'
import CompaniesAlbums from '../../../requests/CompaniesAlbums'
import { DomainsItems } from '../../../tools'
import { Typography } from '@material-ui/core'
import AccountBalance from '@material-ui/icons/AccountBalance'
import GestionFields from '../../common/fields/GestionFields'
import Button from '@material-ui/core/Button'
import { IconButton } from '@material-ui/core'
import { HistoryIcon, SearchIcon } from '../../../constants/icons'
import { VisibilityIcon, SettingsIcon, RepeatIcon } from '../../../constants/icons'
import PropertiesField from '../../common/fields/PropertiesField'
import SearchListField from '../../common/fields/SearchListField'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { OnUpdateAlbumFiche, onChangeParent, OnUpdateAlbumEditorial} from '../../../actions/companiesAlbums'
import AlbumInfoGlobal from './AlbumInfoGlobal'
import LocalStorage from '../../../tools/LocalStorage'
import { AlertDialogSlide } from '../../common'
import AssistantPack from '../../assistantpacks/AssistantPack'
import { translate } from 'ra-core'

const styles = theme => ({
	container: {
		display: 'flex'
	},
	item: {
		marginRight: '1rem',
		marginLeft: '5px'
	},
	formControl: {
		margin: '.5em .5em 1.5em 2.2em',
		minWidth: '230px',
		height: '25px'
	},
	customSelect: {
		//position: 'absolute',
		top: '8px',
		margin: theme.spacing(1),
		//padding:'6px 8px',
		maxWidth: '90px',
		minHeight: '40px',
		backgroundColor: '#fff',
		borderRadius: '4px',
	},
	button: {
		fontSize: '.9em',
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		padding: '.5 1 .5 1',
		marginLeft: '9px',
		width: '80%',
		height: '3em'
	},

})

class CompaniesalbumsFiche extends Component {

	constructor(props) {
		super(props)
		this.state = {
			itemRecord: {},
			albums: [],
			types: [],
			childCount: [],
			selectedPerentId: '',
			Listproperties: [],
			count: 0,
			propertiesShow: false,
			typeId: 0,
			ParentID: 0,
			openSearch: false,
			openPack: false,
			isRecurrent: false,
			isAdulte: false
		}
	}
	// OnchangeParent = (event) => {
	// 	this.setState({ selectedPerentId: event.target.value })
	// }
	getalbumsType = () => {
		return Object.values(DomainsItems.getItems(21))
	}
	componentDidMount() {
		const { record, OnUpdateAlbumFiche, type, onChangeParent, albumFiche, OnUpdateAlbumEditorial,selectedAlbumParent} = this.props
		//Gestion historique
		if (record && record.name && record.id !=='')
			{
				LocalStorage.history(record.id, '2', record.name, '0')
			}
		let parentid = window.location.href.split('/')[6]
		CompaniesAlbums.getAllalbums().then((json) => {
			if (json.data !== undefined) {
				this.setState({ albums: json.data })
			}
		})
		CompaniesAlbums.getAlbumsChildsCount().then((json) => {
			if(json.data)
			this.setState({ childCount: json.data.filter(s=>s.id === record.id)[0] })
		})
		OnUpdateAlbumFiche(this.state.itemRecord)
		this.setState({ types: this.getalbumsType() })
		this.setState({ itemRecord: record })
		this.setState({ typeId: record ? record.typeid : 0 })
		this.setState({
			isRecurrent: (record.attributes ===1 || record.attributes ===3 )? true : false,
			isAdulte: (record.attributes === 2 || record.attributes ===3 )? true : false
		})
		if (type === 'create' && parentid && parentid !== 0 ) {
			onChangeParent(parentid === '0' ? 1 : parentid)
			let album = {}
			album.parentid = parentid === '0' ? 1 : parentid
			// if(parentid === 0)			
			// 	album.parentid = selectedAlbumParent
			// else
			//	album.parentid = parentid
			album.properties = '<PROPS><PROP NAME="PACK" VALUE="0"/></PROPS>'
			//onChangeParent(album.parentid)
			this.setState({ itemRecord: album })

		}
		else {
			if (albumFiche.id === 0 && type !== 'create') {
				this.setState({ itemRecord: record })
				OnUpdateAlbumFiche(record)
				OnUpdateAlbumEditorial({})
				onChangeParent(record.parentid)
			}
			if (record.id === albumFiche.id) {
				this.setState({ itemRecord: albumFiche })
				this.setState({ typeId: albumFiche ? albumFiche.typeid : 0 })
				OnUpdateAlbumFiche(albumFiche)
				OnUpdateAlbumEditorial({})
				onChangeParent(albumFiche.parentid)
			}

		}

 	}

	componentDidUpdate() {
		const { record } = this.props
		//Gestion historique
		if (record && record.name && record.id !=='')
		LocalStorage.history(record.id, '2', record.name, '0')
	}
	getMuiTheme = () => createTheme({
		overrides: {
			MuiBox: {
				root: {
					paddingTop: '5px'
				}
			},
			MuiGrid: {
				container: {
					paddingBottom: '15px'
				}
			},
			MuiTypography: {
				root: {
					paddingLeft: '10px'
				}
			},
			MuiCardContent: {
				root: {
					'&:first-child': {
						paddingTop: 0,
						paddingBottom: 0,
						paddingLeft: 8,
						paddingRight: 8,
					},
				}
			},

			MuiOutlinedInput: {
				multiline: {
					width: '480px'
				},
				input: {
					padding: '10px 14px'
				},
				'root': {  // - The Input-root, inside the TextField-root
					'&:hover fieldset': {
						borderColor: '#288DB1 !important', // - Set the Input border when parent has :hover
					},
					'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
						borderColor: '#288DB1 !important',
					},
				},
			},
			MuiFormLabel: {
				root: {
					"&$focused": {
						"color": "#288DB1"
					},
				}
			},

			MuiInputBase: {
				input: {
					padding: '10px 14px'
				}
			},
			MuiDialog: {
				paperWidthSm: {
					maxWidth: '1100px'
				}
			}

		},
	})

	getValueFromProperties = (property, label) => {
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(property, "text/xml")
		let Properties = []

		// if (this.state.count === 0) this.setState({ count: 1 })
		if (xmlDoc.getElementsByTagName("PROPS")[0] !== undefined) {
			for (let i = 0; i < xmlDoc.getElementsByTagName("PROPS")[0].childNodes.length; i++) {
				let newProp = {
					name: xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("NAME"),
					value: xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("VALUE")
				}
				Properties.push(newProp)
			}
		}
		if (this.state.Listproperties.length === 0 && Properties.length !== 0)
			this.setState({ Listproperties: Properties })
		return Properties.find(s => s.name === label) !== undefined ? Properties.find(s => s.name === label).value : ''
	}


	handleClickOpenProperty = () => {
		this.setState({ propertiesShow: !this.state.propertiesShow })
	}
	handleClickOpenPack = () => {
		this.setState({ openPack: !this.state.openPack })
	}
	handleClickOpenImport =() =>{
		const {record} = this.props

		window.open(`${process.env.PUBLIC_URL}/#/Importexport?refid=${record.id}&objectid=2`, '_blank')

	}
	handleClickOpenJobs =() =>{
		const {record} = this.props

		window.open(`${process.env.PUBLIC_URL}/#/JobHistory?refid=${record.id}&typeid=Null&objectid=2`, '_blank')

	}
	handleClickClosePack = () => {
		const {record} = this.props
		CompaniesAlbums.getAlbum(record.id).then((data) => {
			OnUpdateAlbumFiche(data.data)
			this.setState({itemRecord: data.data
			})
		})
		this.setState({ openPack: false })
	}
	parentIdChange = (event) => {
		this.setState({ openSearch: true })
	}
	closeSearch = (event) => {
		this.setState({ openSearch: false })
	}
	handleChangeGeneralItem = (event, field) => {
		const { albumFiche, OnUpdateAlbumFiche, selectedAlbumParent, type } = this.props
		let album = this.state.itemRecord
		album.companyid = 1
		if (field === 'name') album.name = event.target.value
		if (field === 'rank') album.rank = event.target.value
		if (field === 'type') {
			album.typeid = event.target.value
			this.setState({ typeId: event.target.value })
		}
		if (field === 'BDDP_ID') album.bddpId = event.target.value
		if (!selectedAlbumParent && type === 'create') album.parentid = 1
		// else
		// album.parentid = selectedAlbumParent 

		if (albumFiche.parentid && albumFiche.parentid !== album.parentid) album.parentid = albumFiche.parentid

		if (field === 'BDDP_ID' || field === 'HAPILIST_NAME') {
			let parser = new DOMParser()
			const serializer = new XMLSerializer()
			let xmlDoc = parser.parseFromString(this.state.itemRecord.properties, "text/xml")
			let propName = []
			const len = xmlDoc.getElementsByTagName("PROPS")[0] && xmlDoc.getElementsByTagName("PROPS")[0].childNodes ? xmlDoc.getElementsByTagName("PROPS")[0].childNodes.length : 0
			if (xmlDoc.getElementsByTagName("PROPS")[0] !== undefined) {
				for (let i = 0; i < len; i++) {
					if (xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("NAME") === field) {
						propName.push(xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("NAME"))
						xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].setAttribute("VALUE", event.target.value)
						album.properties = serializer.serializeToString(xmlDoc)
					}
				}
				if (propName.find(s => s === field) === undefined) {
					let newEle = xmlDoc.createElement("PROP")
					xmlDoc.getElementsByTagName("PROPS")[0].appendChild(newEle)
					xmlDoc.getElementsByTagName("PROPS")[0].childNodes[len].setAttribute("NAME", field)
					xmlDoc.getElementsByTagName("PROPS")[0].childNodes[len].setAttribute("VALUE", event.target.value)
					album.properties = serializer.serializeToString(xmlDoc)
				}
			} else {
				let newXML = parser.parseFromString('<PROPS></PROPS>', "text/xml")
				if (propName.find(s => s === field) === undefined) {
					let newEle = newXML.createElement("PROP")
					newXML.getElementsByTagName("PROPS")[0].appendChild(newEle)
					newXML.getElementsByTagName("PROPS")[0].childNodes[len].setAttribute("NAME", field)
					newXML.getElementsByTagName("PROPS")[0].childNodes[len].setAttribute("VALUE", event.target.value)
					album.properties = serializer.serializeToString(newXML)
				}
			}
		}
		if (field === 'PACK') {
			let parser = new DOMParser()
			const serializer = new XMLSerializer()
			let xmlDoc = parser.parseFromString(this.state.itemRecord.properties, "text/xml")
			let propName = []
			const len = xmlDoc.getElementsByTagName("PROPS")[0] && xmlDoc.getElementsByTagName("PROPS")[0].childNodes ? xmlDoc.getElementsByTagName("PROPS")[0].childNodes.length : 0
			if (xmlDoc.getElementsByTagName("PROPS")[0] !== undefined) {
				for (let i = 0; i < len; i++) {
					if (xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("NAME") === field) {
						propName.push(xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("NAME"))
						xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].setAttribute("VALUE", event.target.checked ? 1 : 0)
						album.properties = serializer.serializeToString(xmlDoc)
						album.isPack = event.target.checked

					}
				}
				if (propName.find(s => s === field) === undefined) {
					let newEle = xmlDoc.createElement("PROP")
					xmlDoc.getElementsByTagName("PROPS")[0].appendChild(newEle)
					xmlDoc.getElementsByTagName("PROPS")[0].childNodes[len].setAttribute("NAME", field)
					xmlDoc.getElementsByTagName("PROPS")[0].childNodes[len].setAttribute("VALUE", event.target.checked ? 1 : 0)
					album.properties = serializer.serializeToString(xmlDoc)
					album.isPack = event.target.checked

				}
			} else {
				let newXML = parser.parseFromString('<PROPS></PROPS>', "text/xml")
				if (propName.find(s => s === field) === undefined) {
					let newEle = newXML.createElement("PROP")
					newXML.getElementsByTagName("PROPS")[0].appendChild(newEle)
					newXML.getElementsByTagName("PROPS")[0].childNodes[len].setAttribute("NAME", field)
					newXML.getElementsByTagName("PROPS")[0].childNodes[len].setAttribute("VALUE", event.target.checked ? 1 : 0)
					album.properties = serializer.serializeToString(newXML)
					album.isPack = event.target.checked

				}
			}
		}
		if(field ==='RECURRENT'){
			this.setState({isRecurrent: event.target.checked})
			
			if(event.target.checked){
				album.attributes = this.state.isAdulte ? 3 : 1 
			}else{
				album.attributes = this.state.isAdulte ? 2 : 0 
			} 

		}
		if(field ==='ADULTE'){
			this.setState({isAdulte: event.target.checked})

			if(event.target.checked){
				album.attributes = this.state.isRecurrent ? 3 : 2 
			}else{
				album.attributes = this.state.isRecurrent ? 1 : 0 
			}
		}
		OnUpdateAlbumFiche(album)
		this.setState({ itemRecord: album })
	}
	render() {
		const { record, classes, create, type, selectedAlbumParent, albumFiche, OnUpdateAlbumFiche, translate ,AlbumsData} = this.props
		let defaultParent = selectedAlbumParent ? selectedAlbumParent : ''

		let parentId = defaultParent
		if (type === 'create' && this.state.albums.filter(x => x.id.toString() === selectedAlbumParent)[0]) {
			parentId = `${this.state.albums.filter(x => x.id.toString() === selectedAlbumParent)[0].name} (${selectedAlbumParent})`
		} else {
			if ((!selectedAlbumParent || !create) && (this.state.albums.filter(x => x.id === this.state.itemRecord.parentid)[0]))
				parentId = `${this.state.albums.filter(x => x.id === this.state.itemRecord.parentid)[0].name} (${this.state.itemRecord.parentid})`

			if (create && this.state.albums.filter(x => x.id === defaultParent)[0]) {
				parentId = `${this.state.albums.filter(x => x.id === defaultParent)[0].name} (${defaultParent})`
			}
		}
		const modalContent = {
			title: 'ASSISTANT PACK',
			description: <AssistantPack defaultalbum={record} />,
			disagreeButtonText: translate('ra.action.undo'),
			agreeButtonText: translate('ra.action.confirm')
		}
		return (
			<ThemeProvider theme={this.getMuiTheme()}>
				<Grid container direction="row" justifyContent="space-between" >
					<Grid item xs={9}>
						<Grid container>
							<Grid item xs={10}>
								<AlbumInfoGlobal recordelt={this.state.itemRecord} type={type} handleChangeGeneral={(event) => this.handleChangeGeneralItem(event, 'name')} create={create} />
							</Grid>
							<Grid item md={10} sm={12} xs={12}>
								<Grid container style={{ marginTop: '20px', marginBottom: '10px', marginLeft: '.5em' }} direction="row">
									<AccountBalance style={{ marginTop: '3px' }} /><Typography variant="h6" component="h6" >Détails</Typography>
								</Grid>
								<Grid container direction="row">
									<Grid item md={create ? 5 : 6} sm={create ? 3 : 4} xs>
										<CustomTextField label="Album parent"
											required={true}
											value={parentId}
											width='35%' fullWidth={true} />
									</Grid>
									{(create)
										? <Grid item md={1} sm={1} xs>
											<IconButton onClick={this.parentIdChange}>
												<SearchIcon />
											</IconButton>
											<SearchListField open={this.state.openSearch} close={this.closeSearch} exceptionItem={record} OnUpdateAlbumFiche={OnUpdateAlbumFiche} albumFiche={albumFiche}
												Title='Liste des albums' ListToSearch={this.state.albums} TypeItem='Albums' multiSelect={false} source='parentId' />
											{/* <FavoriteItem favStatus={value} object='cet album' refId={tableMeta.rowData[1]}  
							objectId={objectId} ObjectFav={this.state.contactFav} searchAlbumfunction={()=>this.searchfunction(searchValue)}
							 count={AlbumsData ? AlbumsData.filter(x=>x.favorite === 1).length : 0}/> */}
										</Grid> : <div />}
									<Grid item md={6} sm={4} xs>
										{!create ? <CustomTextField label="Type" required={true} create={create} value={DomainsItems.getFormattedLabel(21, this.state.itemRecord.typeid)} width='35%' fullWidth={true} /> :
											<CustomSelect propsType="domainItem" required={true} values={this.state.types} value={this.state.typeId} label="Type" handleChange={(e) => this.handleChangeGeneralItem(e, 'type')} fullWidth={true} />}
									</Grid>
									<Grid item md={3} sm={3} xs>
										<CustomTextField label="Rang" create={create} required={true} value={this.state.itemRecord.rank} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'rank')} />
									</Grid>
									<Grid item md={3} sm={3} xs>
										<CustomTextField label="ID BDDP" create={create} value={this.getValueFromProperties(this.state.itemRecord.properties, 'BDDP_ID')} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'BDDP_ID')} fullWidth={true} />
									</Grid>
									{/* <Grid item md={3} sm={3} xs>
										<CustomTextField label="EPG ID" create={create} value={this.getValueFromProperties(this.state.itemRecord.properties, 'ID_EPG')} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'ID_EPG')} fullWidth={true} />
									</Grid> */}
									{/* <Grid item md={3} sm={3} xs>
										<CustomTextField label="Genre M7" create={create} value={this.getValueFromProperties(this.state.itemRecord.properties, 'M7_GENRE')} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'M7_GENRE')} fullWidth={true} />
									</Grid> */}
									{/* <Grid item md={6} sm={6} xs>
										<CustomTextField label="Référence Allociné" create={create} value={this.getValueFromProperties(this.state.itemRecord.properties, 'ALLOCINE_CODE')} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'ALLOCINE_CODE')} fullWidth={true} />
									</Grid> */}
									<Grid item md={6} sm={6} xs>
										<CustomTextField label="Nom liste HAPI" create={create} value={this.getValueFromProperties(this.state.itemRecord.properties, 'HAPILIST_NAME')} handleChange={(event, field) => this.handleChangeGeneralItem(event, 'HAPILIST_NAME')} fullWidth={true} />
									</Grid>
									
									<Grid container style={{ marginLeft: '15px' }}>
									<Grid item md={3} sm={3} xs >
										<CustomCheckBox label="Eligible pack" disabled={!create ||this.state.childCount && this.state.childCount.albumsmoviesCount === 0 } labelPlacement='end'
											value={this.getValueFromProperties(this.state.itemRecord.properties, 'PACK') === '1' ? true : false}
											width='35%' onChange={(event, field) => this.handleChangeGeneralItem(event, 'PACK')} fullWidth="true" />
									</Grid>
									<Grid item md={3} sm={3} xs >
										<CustomCheckBox label="Récurrent ?" disabled={!create} labelPlacement='end'
											value={this.state.isRecurrent}
											width='35%' onChange={(event, field) => this.handleChangeGeneralItem(event, 'RECURRENT')} fullWidth="true" />
									</Grid>
									<Grid item md={3} sm={3} xs >
										<CustomCheckBox label="Autoriser l'adulte ?" disabled={!create} labelPlacement='end'
											value={this.state.isAdulte }
											width='35%' onChange={(event, field) => this.handleChangeGeneralItem(event, 'ADULTE')} fullWidth="true" />
									</Grid>
									
									</Grid>
									{/* {this.state.childCount && this.state.childCount.albumsmoviesCount === 0 ? <div/>:
									<Grid item md={3} sm={3} xs style={{ marginLeft: '-15px' }}>
										<CustomCheckBox label="Eligible pack" disabled={!create ||this.state.childCount && this.state.childCount.albumsmoviesCount === 0 }
											value={this.getValueFromProperties(this.state.itemRecord.properties, 'PACK') === '1' ? true : false}
											width='35%' onChange={(event, field) => this.handleChangeGeneralItem(event, 'PACK')} fullWidth="true" />
									</Grid>
									} */}

								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={3}  >
						<GestionFields classes={classes} record={record} />
						<div hidden={this.state.Listproperties.length === 0}>
							<Button color="primary" variant="contained" className={classes.button} onClick={this.handleClickOpenProperty}><VisibilityIcon style={{ marginRight: '.5em' }} />VOIR PROPRIETES</Button>
						</div>
						<div hidden={this.getValueFromProperties(this.state.itemRecord.properties, 'PACK') === '1' ? false : true}>
							<Button color="primary" variant="contained" disabled={create} onClick={this.handleClickOpenPack} className={classes.button} style={{ marginTop: '.3em' }} ><SettingsIcon style={{ marginRight: '.5em' }} />GESTION PACK</Button>
						</div>
						<div>
							<Button color="primary" variant="contained" disabled={create} onClick={this.handleClickOpenImport} className={classes.button} style={{ marginTop: '.3em' }} ><RepeatIcon
							 style={{ marginRight: '.5em' }} />IMPORT / EXPORT</Button>
						</div>
						<div>
							<Button color="primary" variant="contained" disabled={create} onClick={this.handleClickOpenJobs} className={classes.button} style={{ marginTop: '.3em' }} ><HistoryIcon
							 style={{ marginRight: '.5em' }} />Jobs</Button>
						</div>
					</Grid>
					<CustomDisplayDialog info="proprietesList" title='LISTE PROPRIETES' handleClose={this.handleClickOpenProperty} open={this.state.propertiesShow} content={<PropertiesField propertiesItem={this.state.itemRecord.properties} />} width='xs' />
					<AssistantPack  defaultalbum={record}
						open={this.state.openPack}
						onClose={this.handleClickClosePack}
						modalContent={modalContent}
						launchActivationProcess={this.handleSubmit} 
						OnUpdateAlbumFiche = {OnUpdateAlbumFiche}/>

				</Grid>
				{/* </CardContent>
					</Card> */}
			</ThemeProvider >

		)
	}
}
const mapStateToProps = state => ({
	...state,
	selectedAlbumParent: state.reducer.albums.selectedAlbumParent,
	albumFiche: state.reducer.albums.albumFiche,
	AlbumsData: state.reducer.albums.AlbumsData,

})

const mapDispatchToProps = ({
	OnUpdateAlbumFiche,
	onChangeParent,
	OnUpdateAlbumEditorial
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(CompaniesalbumsFiche))

