import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import AccountBalance from '@material-ui/icons/AccountBalance'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { PromotionsType } from '../../../constants/types'
import { PromotionsVoucher, PromotionsUsers } from './index'
import { CustomTextField, CustomSelect, CustomDisplayDialog,InputLabelTitle } from '../../layout/GlobalStyles'
import { onPromotionUpdate, onGetPromo, onPromotionVoucherExist } from '../../../actions/Promotion'
import { DatePickerCanal } from '../../common/inputs'
import {  withStyles } from '@material-ui/core'
import { Promotions } from '../../../requests'
import { VisibilityIcon, HistoryIcon  } from '../../../constants/icons'

import GestionFields from '../../common/fields/GestionFields'
import Button from '@material-ui/core/Button'
import { DateFormatter,DomainsItems } from '../../../tools'
const styles = ({
	customDateClass: {
		// marginTop: '15px',
		// padding: '4px',
		// paddingTop: '15px'
		//marginBottom :'-25px'
	},
	customInputClass: {
		width: '100px',
		marginRight: '5px'
	},
	buttonHapi: {
		fontSize: '.9em',
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		marginTop: '1.2em',
		padding: '.5 1 .5 1',
		width: '85%',
		height: '3em'
	},
})

const mode = [
	{ id: PromotionsType.VOUCHER, name: `Voucher (${PromotionsType.VOUCHER })`  },
	{ id: PromotionsType.GLOBAL, name:    `Global (${PromotionsType.GLOBAL })`  },
	{ id: PromotionsType.UTILISATEUR, name: `Utilisateur (${PromotionsType.UTILISATEUR })` },
]
const periodes = [
	{ id: '', name: '' },
	{ id: 'D', name: 'Jours' },
	{ id: 'M', name: 'Mois' },
	{ id: 'Y', name: 'Année' }

]
const labels = {
	margin: '.5em .5em .5em 0',
	color: '#288DB1',
	fontWeight: 'bold',
	fontSize: '13px',
	fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	lineHeight: 1
}

// const titleStyle = {
// 	marginBottom: '1em',
// 	marginLeft: '.5em'
// }

// const textField = {
// 	marginBottom: 5,
// 	width: '95%'
// }

const elementStyle = {
	marginBottom: 0,
	paddingRight: 0
}

const elementStyleEdito = {
	// marginTop: '1rem',
	// paddingRight: 0
}

const constPromoType = [
	{ id: PromotionsType.GLOBAL, name: 'Global' },
	{ id: PromotionsType.UTILISATEUR, name: 'Utilisateur' },
	{ id: PromotionsType.VOUCHER, name: 'Voucher' },
]

function PromotionsGeneral (props) {

	let { record, classes, create = false, type, onPromotionUpdate, promotion, controlField, isVoucherExist, isSubscribersExist } = props
	
	const [openChangeTypeMessage, setOpenChangeTypeMessage] = React.useState(false)
	const [changeTypeMessage, setChangeTypeMessage] = React.useState('')
	const [promoHAPI, setpromoHAPI] = React.useState('')
	const [nbDuration, setnbDuration] = React.useState('')
	const [durationPeriode, setdurationPeriode] = React.useState('')
	const [duration, setDuration] = React.useState('P0Y0M0DT0H0M0S')
	const [listType, setListType] = React.useState([])

	let promoCheckbox, promoStartDate, promoEndDate, style, displayTypeIcon, typeInformationsDisplay, createOn
	let promoName = [] 
	let promoType = []
	let promoDescription = []
	let editoName = []
	let editoDescription = []
	useEffect(() => {
			if(listType.length ===0){
				let promoTypes = Object.values(DomainsItems.getItems(114))
				setListType(promoTypes)
			}
		
			if (record && record.id !== 0) {
				Promotions.getHapiPromotion(record.id).then((json) => {
					if (json.data !== undefined) {
						setpromoHAPI(json.data)
					}
				}) 
				getDurationValue()
				

			}
			 
		
	})
 	const 	getDurationValue = () =>{
		let parser = new DOMParser()
 		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		let duration = xmlDoc.getElementsByTagName("TTL")[0].getAttribute("DURATION") 
		 if(duration || create){
			periodes.forEach(element => {
				let value = DateFormatter.getPeriodeValueFromDatatype(duration,element.id)
				if(value && value != 0){
					setnbDuration (value)
					setdurationPeriode(element.id)  
				}
			})
		}else{
			setnbDuration ('')
			setdurationPeriode('')  
		}
	
	}
	const displayTypeInformations = (source = null, classes) => {
		let displayList = []
		if (source.typeid === constPromoType[1]['id']  ) {
			displayList.push( <PromotionsUsers source={source} classes={classes} type={type} placeholder={'Id Utilisateur'} create={create} /> )
		}
		if (source.typeid === constPromoType[2]['id'] ) {
			displayList.push( <PromotionsVoucher source={source} classes={classes} create={create} type={type} placeholder={'Code Voucher'} /> )
		} 
		
		return displayList
	}
		
	const handleChangeDescriptionValue = (event) => {
		let prom = promotion
		prom.description = event.target.value
		onPromotionUpdate(promotion)
	}

	const handleChangeEditoNameValue = (event) => {
		let prom = promotion
		prom.nameedito = event.target.value
		onPromotionUpdate(promotion)
	}

	const handleChangeEditoDescriptionValue = (event) => {
		let prom = promotion
		prom.descriptionedito = event.target.value
		onPromotionUpdate(promotion)
	}

	const handleChangeTypeValue = (event) => {
		let prom = promotion
		if(prom.typeid === 2 && event.target.value !== prom.typeid) {
			prom.typeid = event.target.value
			onPromotionUpdate(promotion)
		} else if((prom.typeid === 1) && event.target.value !== prom.typeid && isVoucherExist.exist === false){
			prom.typeid = event.target.value
			onPromotionUpdate(promotion)
		} else if((prom.typeid === 3) && event.target.value !== prom.typeid && isSubscribersExist === false){
			prom.typeid = event.target.value
			onPromotionUpdate(promotion)
		} else if(prom.typeid === 0) {
			prom.typeid = event.target.value
			onPromotionUpdate(promotion)
		}

		if((prom.typeid === 1) && event.target.value !== prom.typeid && isVoucherExist.exist === true) {
			setChangeTypeMessage('Impossible de modifier le type de cette promotion, car des codes vouchers ont été générés')
			setOpenChangeTypeMessage(true)
		}
		else if((prom.typeid === 3) && event.target.value !== prom.typeid && isSubscribersExist === true) {
			setChangeTypeMessage('Impossible de modifier le type de cette promotion, car des données ont été saisies dans Utilisateurs')
			setOpenChangeTypeMessage(true)
		}
	}

	const handleChangeStartDateValue = (updatedDate) => {
		const date = new Date(Object.values(updatedDate).slice(0, 16).join(''))
		let updatedDateObject = null
		
		if(date instanceof Date && !isNaN(date)) {
			updatedDateObject = Object.values(updatedDate).slice(0, 16).join('')
		}
 		let prom = promotion
		prom.startdate = updatedDateObject
		onPromotionUpdate(promotion)
	}

	const handleChangeEndDateValue = (updatedDate) => {
		const date = new Date(Object.values(updatedDate).slice(0, 16).join(''))
		let updatedDateObject = null
		
		if(date instanceof Date && !isNaN(date)) {
			updatedDateObject = Object.values(updatedDate).slice(0, 16).join('')
		}

		let prom = promotion
		prom.enddate = updatedDateObject
		onPromotionUpdate(promotion)
	}

	const handleCloseChangeTypeMessage = () => {
		setOpenChangeTypeMessage(false)
	}
	const handleOpenUrl = (url,type) => {
		window.open(url, "_blank")

		// fetch(url, { method: "GET" })
		//   .then((response) => {
		// 	if (response.ok) {
		// 	  // URL is valid, open it in a new window
		// 	  window.open(url, "_blank")
		// 	} else {
		// 	  // URL is not valid, show error message
		// 	  throw new Error("Invalid URL")
		// 	}
		//   })
		//   .catch((error) => {
		// 	console.error(error)
		// 	// Show error message to the user
		// 	alert("Failed to open URL: " + error.message)
		//   })
	}
	const onResetPeriode =()=>{
		setnbDuration('')
		setdurationPeriode('')
		setDuration('')
		let parser = new DOMParser()
 		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		const serializer = new XMLSerializer()
		let prom = promotion
		xmlDoc.getElementsByTagName("TTL")[0].setAttribute("TYPEID", 2)
		xmlDoc.getElementsByTagName("TTL")[0].removeAttribute("DURATION")
		prom.rules = serializer.serializeToString(xmlDoc)
		onPromotionUpdate(prom)
	}
	const updateDuration = (e,item) => {
		let parser = new DOMParser()
 		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		const serializer = new XMLSerializer()
		let prom = promotion
 		let months = 0
		let days = 0
		let year = 0
		let unit = durationPeriode
		let value = nbDuration
    	// Construire la chaîne de date
		if(item === 'nombre'){
			setnbDuration(e.target.value)
			value = e.target.value
		}
		if(item === 'periode'){
			setdurationPeriode(e.target.value )
			unit = e.target.value
		}
		switch (unit) {
			case 'Y':
				year = value
				break
			case 'D':
				days = value
				break
			case 'M':
				months = value
				break
			default:
				break
		}
		const dateString = `P${year}Y${months}M${days}DT0H0M0S`
 		setDuration(dateString)
 		if (xmlDoc.getElementsByTagName("TTL")[0]  ) {

			if((value== 0 ||value =='') || unit ===''){
				xmlDoc.getElementsByTagName("TTL")[0].setAttribute("TYPEID", 2)
				xmlDoc.getElementsByTagName("TTL")[0].removeAttribute("DURATION")
				prom.rules = serializer.serializeToString(xmlDoc)
				onPromotionUpdate(prom)
			}else
			if(value > 0 && unit != ''){
				xmlDoc.getElementsByTagName("TTL")[0].setAttribute("TYPEID", 1)
				xmlDoc.getElementsByTagName("TTL")[0].setAttribute("DURATION", dateString)
				prom.rules = serializer.serializeToString(xmlDoc)
				onPromotionUpdate(prom)
			}
				
		}
 	}
	if (create) {
		promoType.push(<CustomSelect fullWidth={true} values={listType}  propsType ="domainItem" value={promotion.typeid} label="Type" handleChange={handleChangeTypeValue} required={true} errorLabel={controlField && controlField.typeid != "" ? controlField.typeid : null} />)

		promoStartDate = <DatePickerCanal withTime={true} dateOnly={false}  disabled ={!create} minDate={new Date()} source="startdate" label="Date début" timelabel="Heure début" required={true}  shrink={true}  dateType = {'start'} onChange={handleChangeStartDateValue} value={promotion.startdate} errorLabel={controlField && controlField.startdate != "" ? controlField.startdate : null}/>
		promoEndDate = <DatePickerCanal withTime={true} dateOnly={false}   source="enddate" minDate={promotion.startdate!== null ?promotion.startdate : new Date()} disabled ={!create} timelabel="Heure fin" label="Date fin" required={true}  shrink={true} dateType = {'end'}  onChange={handleChangeEndDateValue} value={promotion.enddate} errorLabel={controlField && controlField.enddate != "" ? controlField.enddate : null}/>

		promoDescription.push(<CustomTextField label="Description" multiline="true" rows={2} fullWidth="true" value={promotion.description} handleChange={handleChangeDescriptionValue} create={create} textFieldLenght={300}/>)
		editoName.push(<CustomTextField label="Nom éditorial" fullWidth="true" value={promotion.nameedito} handleChange={handleChangeEditoNameValue} create={create} textFieldLenght={64}/>)
		editoDescription.push(<CustomTextField label="Description éditorial" value={promotion.descriptionedito} multiline="true" rows={2} fullWidth="true" handleChange={handleChangeEditoDescriptionValue} create={create} textFieldLenght={300}/>)
		typeInformationsDisplay = displayTypeInformations(promotion, classes)
	} else {
		let promoTypeInfo = {id: 0, name: ""}
		listType.forEach(element => {
			if (element.itemid === promotion.typeid) {
				promoTypeInfo.id = element.itemid
				promoTypeInfo.name = element.label +` (${element.itemid})`
			}
		})

		promoCheckbox = <FormControlLabel style={{marginLeft: '1em', position: 'relative', top: '25%'}} control={<Switch checked={record.closed} color="primary" />} label="Clôture" labelPlacement="start" />

		promoName.push(<TextField value={promotion.name} label="Nom promotion" fullWidth InputLabelProps={{style: { color: '#fff' } }} /> )
		promoType.push(<CustomTextField label="Type" value={promoTypeInfo.name} fullWidth={true}/>)
		promoStartDate = <DatePickerCanal  withTime={true} dateOnly={false} source="startdate" label="Date début" disabled="true" timelabel="Heure début" shrink={true} style={{width: "100%" }} dateType = {'start'} value={promotion.startdate}  />
		promoEndDate = <DatePickerCanal withTime={true} dateOnly={false}  source="enddate" dateType = {'end'} label="Date fin" timelabel="Heure fin" disabled="true" shrink={true} style={{width: "100%" }} value={promotion.enddate}/>

		promoDescription.push(<CustomTextField label="Description" value={promotion.description} multiline="true" rows={2} fullWidth="true"/>)
		editoName.push(<CustomTextField label="Nom éditorial" fullWidth="true" value={promotion.nameedito} />)
		editoDescription.push(<CustomTextField label="Description éditorial" value={promotion.descriptionedito} multiline="true" rows={2} fullWidth="true"/>)
		typeInformationsDisplay = displayTypeInformations(promotion, classes)
	}

	const handleClickOpenJobs =() =>{
		console.log(record)

		window.open(`${process.env.PUBLIC_URL}/#/JobHistory?refid=${record.id}&typeid=Null&objectid=6`, '_blank')

	}
	return (
		<div className={ classes.cardPaddingMargin }>
			{ createOn }
			<CustomDisplayDialog info="ERREUR LORS DU CHANGEMENT DE TYPE DE PROMOTION" handleClose={handleCloseChangeTypeMessage} open={openChangeTypeMessage} content={changeTypeMessage} width="sm"/>
			<Grid container spacing={8} direction="row" justify="space-between" >
				<Grid item md={8} sm={12} xs={12}>
					<Grid container direction="row">
						{/* <Grid item md={4} sm={4} xs={12}>
							<AccountBalance />
							<Typography variant="title" style={ titleStyle }>Détails</Typography>
						</Grid> */}
						<Grid container style={{ marginTop: '20px', marginBottom: '10px', marginLeft: '.5em' }} direction="row">
							<AccountBalance style={{ marginTop: '3px', marginRight: '0.5em' }} /><Typography variant="h6" component="h6" >Détails</Typography>
						</Grid>
						<Grid container spacing={4} style={ {marginRight: '-7px'} }  >
							<Grid item md={4} sm={4} >
								{ promoType }
							</Grid>
							<Grid item md={4} sm={4} >
								{ promoStartDate }
							</Grid>
							<Grid item md={4} sm={4} >
								{ promoEndDate }
							</Grid>
						</Grid>
 						<Grid container spacing={4}  style={ {marginRight: '-7px'} }   >
								
								<Grid item  md={4} sm={4} >
									<CustomTextField type='number'  label='Durée de validité' value={nbDuration} fullWidth={true} create={create} //removeicon={create && nbDuration } //valueReset
									handleChange={e =>updateDuration(e,'nombre')} errorLabel={(durationPeriode !=='' && nbDuration === '') ? 'Veuillez saisir un nombre': (controlField && controlField.validitytypeduration != "") ? controlField.validitytypeduration : null} />
								</Grid>
								<Grid item  md={4} sm={4} >
									{create ? 
									<CustomSelect values={periodes} label='Période de validité' fullWidth={true} value={durationPeriode}  
									onChange={e =>updateDuration(e,'periode')}  errorLabel={ durationPeriode === '' && nbDuration !== '' ? 'Veuillez sélectionner une période' : (controlField && controlField.validitytypeduration != "") ? controlField.validitytypeduration  : null }/>
									:
									<CustomTextField  fullWidth={true} label='Période de validité'  value={periodes.find(s=>s.id ===durationPeriode) && periodes.find(s=>s.id ===durationPeriode).name}  create={create} />
									}
								</Grid>
								<Grid item  md={4} sm={4}  >
								<Button
									disabled={!create}
									onClick={()=>onResetPeriode()}
									variant="contained"
									// sx={{ mt: 3, mb: 2 }}
									style={{ background: '#288DB1', color: 'white',cursor: 'pointer', marginTop:'8px'  }}
								>
									Réinitialiser
								</Button>
								</Grid>
								
								
						</Grid>
						<Grid item spacing={5} xs={12} style={ elementStyle }>
							{ promoDescription }
						</Grid>
						
						<Grid item spacing={5} xs={12} style={ elementStyleEdito }>
							{ editoName }
						</Grid>
						<Grid item xs={12} style={ elementStyleEdito }>
							{ editoDescription }
						</Grid>
						
					</Grid>
				</Grid>
				<Grid item md={4} sm={12} xs={12} className={type === 'create' ? classes.display : null}>
					<GestionFields classes={classes} record={record} />
					{promotion.typeid !== 1 && 
					<div>
					<Grid item xs={12} >
					<Button color="primary" variant="contained" className={classes.buttonHapi}  onClick={()=>handleOpenUrl(promoHAPI, 'promoHAPI')} ><VisibilityIcon style={{ marginRight: '.5em' }}/>TEST PROMOTION HAPI</Button>
					</Grid>
					 <Grid item xs={12} >
					<Button color="primary" variant="contained" disabled={create} onClick={handleClickOpenJobs} className={classes.buttonHapi} style={{ marginTop: '.5em' }} >
						<HistoryIcon style={{ marginRight: '.5em' }} />Jobs
					</Button>
					</Grid>
					</div>}
				
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '0em'}}>
					{ typeInformationsDisplay }
				</Grid>
			</Grid>
		</div>
	)
}

const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null,
	returned: state.returned ? state.reducer.Promotion.returned : false,
	isVoucherExist: state.reducer.Promotion.isVoucherExist,
	isSubscribersExist: state.reducer.Promotion.isSubscribersExist,
	controlField: state.reducer ? state.reducer.Promotion.controlField : null,
})

const mapDispatchToProps = ({
	onPromotionUpdate,
	onGetPromo,
	onPromotionVoucherExist
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps)
)

export default  withStyles(styles)(enhance(PromotionsGeneral))
