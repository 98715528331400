
import React, { useEffect, useState } from 'react'
import { fetchStart, GET_LIST } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import MUIDataTable from 'mui-datatables'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import Slide from '@material-ui/core/Slide'
import cyan from '@material-ui/core/colors/cyan'
import { CloseIcon, CancelIcon, VisibilityIcon, SyncIcon, InfoIcon, LocalActivityIcon ,HistoryIcon} from '../../../constants/icons'
import { CustomTextField, CustomDisplayDialog, CustomDialog, CustomSwitch, CustomCheckBox } from '../../layout/GlobalStyles'
import dataProvider from '../../../dataProvider'
import { Vouchers, PromotionsVouchers as PromotionsVouchersApi, Promotions } from '../../../requests'
import Papa from 'papaparse'
import { onPromotionUpdate, onGetPromo, onPromotionVoucherExist } from '../../../actions/Promotion'
import XMLViewer from 'react-xml-viewer'
import LinearProgressWithLabel from './../../layout/LinearProgressWithLabel'
import { DatePickerCanal } from '../../common/inputs'
import { DateFormatter } from '../../../tools'
import Paper from '@material-ui/core/Paper'
import Zoom from '@material-ui/core/Zoom'
import Switch from '@material-ui/core/Switch'

import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
const styles = (theme) => ({
	root: {
		margin: 0,
	},
	closeButton: {
		marginLeft: '88%',
		marginTop: '-40px'
	},
	popupTitle: {
		color: 'white',
		fontSize: '18px'
	},
	dialogHeader: {
		padding: '10px 10px 8px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	closeIcon: {
		width: '15px',
		position: 'absolute',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}
	},
	buttonCancel: {
		width: '150px',
		background: '#e23434',
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	customSearchButtonIcon: {
		//marginTop: "1.2em",
		color: theme.palette.text.primary,
		height: '38%',
	},
	button: {
		fontSize: '.9em',
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		marginTop: '1.2em',
		padding: '.5 1 .5 1',
		width: '85%',
		height: '3em'
	},
	buttonModalGenerate: {
		fontSize: '.9em',
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		marginLeft: '1.2em',
		padding: '.5 1 .5 1',
		width: '27%',
		height: '2.8em'
	},
	buttonModal: {
		fontSize: '.9em',
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		padding: '.5 1 .5 1'
	},
	info: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '.5em'
	},
	infoText: {
		fontSize: '.8em',
		color: 'grey',
	},
	infoLogo: {
		padding: '.2em'
	}
})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const PromotionsVouchers = (props) => {
	const { classes, promotion, create, type, controlField, isVoucherExist, onPromotionVoucherExist, onPromotionUpdate } = props
	const [open, setOpen] = useState(false)
	const [openGenerate, setOpenGenerate] = useState(false)
	const [voucherInfoExist, setVoucherInfoExist] = useState(false)
	const [vouchersearch, setVouchersearch] = useState('')
	const [canGenerateVoucher, setCanGenerateVoucher] = useState("")
	const [canOpenGenerateVoucher, setCanOpenGenerateVoucher] = useState(true)
	const [codeNumGenerate, setCodeNumGenerate] = useState(0)
	const [searchVoucher, setsearchVoucher] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [csvLoading, setcsvLoadingState] = useState(false)
	const [openProperty, setOpenProperty] = useState(false)
	const [promoVoucherHAPI, setPromoVoucherHAPI] = useState('')
	//const [returnedVoucherCodeGenerate, setReturnedVoucherCodeGenerate] = React.useState(false)
	const [progress, setProgress] = useState(0)
	//const [timer, setTimer] = React.useState("")
	const [openAlert, setOpenAlert] = useState(false)
	const [continueGen, setContinueGen] = useState(true)
	const [genDateStart, setGenDateStart] = useState("")
	const [genDateEnd, setGenDateEnd] = useState("")
	const [codeSearchVoucherError, setCodeSearchVoucherError] = useState(false)
	const [lenghtCode, setlenghtCode] = useState(0)
	const [prefixLenght, setprefixLenght] = useState(0)
	const [maxCodeVoucherNumber, setMaxCodeVoucherNumber] = useState(0)
	const [isCodeUnique, setCodeUnique] = useState(false)
	const [codeValue, setCodeValue] = useState("")
	const [infini, setInfini] = useState(false)
	const [promoHAPI, setpromoHAPI] = useState('')
	const [performingAction, setPerformingAction] = useState(false)
	const [stopGenCode, setStopGenCode] = useState(false)
	const [nbUse, setNbUse] = useState(1)
	const [infininbUse, setInfiniNbUse] = useState(false)


	useEffect(() => {
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		let usenb = xmlDoc.getElementsByTagName("NBUSE")[0] && xmlDoc.getElementsByTagName("NBUSE")[0].childNodes[0].nodeValue !== '0' ? parseInt(xmlDoc.getElementsByTagName("NBUSE")[0].childNodes[0].nodeValue) : 1
		setNbUse(usenb)
		console.log(usenb)
		setInfiniNbUse(usenb === -1 ? true : false)
		if (promotion.properties) {
			Promotions.getHapiPromotion(promotion.id)
				.then((json) => {
					if (json.data !== undefined) {
						setpromoHAPI(json.data)
					}
				})

			Vouchers.GetPromoVoucherHapi(promotion.id).then(json => {
				if (json.data !== undefined) {
					setPromoVoucherHAPI(json.data)
				}
			})
			setVoucherInfoExist(true)
			Vouchers.countLines(promotion.id).then(response => {
				response.data > 0 ? onPromotionVoucherExist({ exist: true, count: response.data }) : onPromotionVoucherExist({ exist: false, count: 0 })
			})
			if (promotion.codeLength) {
				setlenghtCode(promotion.codeLength)
				CalculMaxCode(promotion.codeLength, null)

			}
			if (promotion.prefix) {
				setprefixLenght(promotion.prefix.length)
				CalculMaxCode(null, promotion.prefix.length)
			}
			if (promotion.singleCode) {
				setCodeUnique(true)
				setCodeValue(promotion.singleCode)
				setlenghtCode(promotion.codeLength)
				if (parseInt(promotion.max) === -1) setInfini(true)
			}
		}
	}, [promotion], onPromotionVoucherExist)
	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (performingAction) {
				const message = "La génération des code est en cours. Êtes-vous sûr de vouloir quitter ?"
				event.preventDefault()
				event.returnValue = message // Standard for most browsers
				return message // For some older browsers
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload)

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		};
	}, [performingAction])
	//   useEffect(() => {
	// 	//window.addEventListener('abored', console.log('stopgen'))
	// 	abortController.signal.addEventListener('abort', console.log('aborted'))

	// 	return () => {
	// 		abortController.signal.removeEventListener('abort', console.log('aborted'))

	// 	 // window.removeEventListener('click', console.log('stopgen'))
	// 	};
	//   }, [stopGenCode]);
	const handleStartAction = (progValue) => {

		// Démarrez votre action ici
		if (progValue != 100)
			setPerformingAction(true);
		if (progValue === 0)
			setPerformingAction(false);

		// Exemple d'une action (simulée) prenant 5 secondes
		const simulateAction = (remainingTime) => {
			const timeId = setTimeout(() => {
				// Mise à jour de la progress
				// const newProgress = calculateNewProgress();
				if (progValue < 100) {
					// Si la progress n'est pas encore à 100, relancez le timer
					simulateAction(remainingTime)
				}
				if (progValue === 100) {
					// Si la progress atteint 100, terminez votre action ici
					setPerformingAction(false)
				}
			}, remainingTime)
			return () => {
				clearTimeout(timeId)
			}
		}
		// Appel initial de la fonction de simulation
		simulateAction(2000)
	}
	const handleClickOpen = () => {
		setOpen(true)
	}
	const handleClickOpenGenerate = () => {
		setCodeNumGenerate(remainingCode)
		setOpenGenerate(true)
		setContinueGen(true)
		setStopGenCode(false)
	}
	const handleClose = () => {
		setCodeSearchVoucherError(false)
		setGenDateStart(null)
		setGenDateEnd(null)
		setsearchVoucher('')
		setOpen(false)
		setsearchVoucher([])
	}

	const handleGenerateClose = () => {
		if (progress > 0 && progress < 100) {
			setOpenAlert(true)
		} else {
			setOpenGenerate(false)
			setProgress(0)
		}
	}

	const CalculMaxCode = (codelen, prefix) => {
		let maxVoucher = 0
		let code = codelen !== null ? codelen : lenghtCode
		let pref = prefix !== null ? prefix : prefixLenght
		if (code !== 0) {
			let NoPrefLen = code - pref
			maxVoucher = Math.round(260 ** parseInt(NoPrefLen / 2))
			if (NoPrefLen % 2 === 1) {
				maxVoucher = maxVoucher * 10
			}
			setMaxCodeVoucherNumber(maxVoucher)

		}

	}
	const onChangeSearch = e => {
		setVouchersearch(e.target.value)
		if (codeSearchVoucherError)
			setCodeSearchVoucherError(false)
	}

	const onChangeNumberGenerate = e => {
		setCanGenerateVoucher("")
		setProgress(0)
		setCodeNumGenerate(e.target.value)
	}

	const exportToCsv = (loaded = false) => {

		let vouchers = []

		if (loaded && searchVoucher.length > 0) {
			searchVoucher.map(d => {
				vouchers.push({ voucher: d.voucher })
			})
			exportCsvFile(vouchers)
		}
		else if (promotion.id !== 0) {
			setcsvLoadingState(true)
			Vouchers.getCsvByPromoId(promotion.id).then(response => {
				response.data.map(d => {
					vouchers.push({ voucher: d.voucher })
				})
				exportCsvFile(vouchers)
				setcsvLoadingState(false)
			})
		}
	}

	const exportCsvFile = (data) => {

		var options = {
			quotes: false,
			quoteChar: '"',
			escapeChar: '"',
			delimiter: ";",
			header: true,
			newline: "\r\n",
			skipEmptyLines: false,
			columns: null
		}

		let vouchers = []

		data.map(d => {
			vouchers.push({ voucher: d.voucher })
		})

		var csv = Papa.unparse(vouchers, options)
		var csvData = new Blob([csv], { type: 'plain/text;charset=utf-8;' })
		var csvURL = null
		if (navigator.msSaveBlob) {
			csvURL = navigator.msSaveBlob(csvData, 'export_voucher_' + promotion.id + '.txt')
		} else {
			csvURL = window.URL.createObjectURL(csvData)
		}
		var tempLink = document.createElement('a')
		tempLink.href = csvURL
		tempLink.setAttribute('download', 'export_voucher_' + promotion.id + '.txt')
		tempLink.click()
	}

	const getMuiTheme = () => createTheme({
		overrides: {
			MuiPaper: {
				elevation4: {
					boxShadow: 'none'
				}
			},
			MUIDataTableToolbarSelect: {
				root: {
					display: "none !important"
				}
			},
			MUIDataTable: {
				responsiveBase: {
					overflow: 'visible'
				}
			},
			MUIDataTableToolbar: {
				actions: {
					display: "none !important"
				}
			},
			MuiDialog: {
				paperWidthLg: {
					minWidth: '1280px',
					//maxHeight: '400px'
					overflow: 'hidden'
				}
			},
			MUIDataTableHeadCell: {
				fixedHeader: {
					top: '-10px'
				}
			}

		},
	})
	// 	const getPromoHAPI = () => {
	// 		let display = []
	// 	   display.push(<XMLViewer xml={promoHAPI} style={{ backgroundColor: 'white', paddingLeft: '2em' }} /> )//<XMLViewer xml={promoHAPI} style={{ backgroundColor: 'white', paddingLeft: '2em' }} />)
	// 	   return display
	//    }
	const searchClick = (type) => {
		setsearchVoucher([])
		// if(vouchersearch.length < 3) {
		// 	setCodeSearchVoucherError(true)
		// }
		if (type === 'ShowAll' || vouchersearch.length >= 3 || vouchersearch.length === 0) {
			if (type === 'ShowAll' || genDateStart.length > 0 || genDateEnd.length > 0 || vouchersearch.length >= 3) {
				setIsLoading(true)
				if (codeSearchVoucherError)
					setCodeSearchVoucherError(false)

				let searchWord = "%"
				if (vouchersearch.length >= 3) {
					searchWord += vouchersearch + "%"
				}

				fetchStart()

				dataProvider(GET_LIST,
					"PromotionsVouchers/Search",
					{
						pagination: { page: 1, perPage: 100 },
						sort: { field: {}, order: {} },
						filter: { id: promotion.id, mode: 1, wordSearch: searchWord, dateGenStart: genDateStart, dateGenEnd: genDateEnd }
					})
					.then(resp => {
						let data = []
						resp.data.forEach(d => data.push(d))
						setsearchVoucher(data)
						setIsLoading(false)

					})
			}
		}
		else {
			setCodeSearchVoucherError(true)
		}
	}

	const debugProperty = () => {
		const { promotion } = props
		let display = []
		display.push(<XMLViewer xml={promotion.properties} style={{ backgroundColor: 'white', paddingLeft: '2em' }} />)
		return display
	}
	const getVoucherHAPI = () => {
		let display = []
		display.push(<XMLViewer xml={promoVoucherHAPI} style={{ backgroundColor: 'white', paddingLeft: '2em' }} />)
		return display
	}
	const handleCloseProperty = () => {
		setOpenProperty(false)
	}

	const handleCloseAlert = () => {
		setOpenAlert(false)
	}

	let abortController = new AbortController()
	const signal = abortController.signal
	const handleStopGenerateCodes = () => {
		//abortController.abort()
		abortController.signal.addEventListener('abort', console.log('aborted'))

		onPromotionVoucherExist({ exist: false, count: 0 })
		setContinueGen(false)
		setOpenAlert(false)
		setOpenGenerate(false)
	}

	const handleClickOpenProperty = () => {
		setOpenProperty(true)
	}

	const handleChangePrefixValue = (event) => {
		// let parser = new DOMParser()
		setprefixLenght(event.target.value.length)
		const { promotion, onPromotionUpdate } = props
		// let xmlDoc = parser.parseFromString(promotion.properties, "text/xml")

		// const serializer = new XMLSerializer()
		let prom = promotion
		if (!isCodeUnique) prom.singleCode = ''
		prom.prefix = event.target.value
		onPromotionUpdate(prom)
		CalculMaxCode(null, event.target.value.length)

	}

	const handleChangeCodeSizeValue = (event) => {
		// let parser = new DOMParser()

		const { promotion, onPromotionUpdate } = props
		setlenghtCode(event.target.value)
		// const serializer = new XMLSerializer()
		let prom = promotion
		prom.codeLength = event.target.value
		if (!isCodeUnique) prom.singleCode = ''
		onPromotionUpdate(prom)
		CalculMaxCode(event.target.value, null)

	}
	const handleChangeNbUseValue = (event) => {
		// let parser = new DOMParser()

		const { promotion, onPromotionUpdate } = props
		const serializer = new XMLSerializer()
		let prom = promotion
		setNbUse(event.target.value ? event.target.value : 1)
		if (event.target.value === -1) setInfiniNbUse(true)
		else setInfiniNbUse(false)
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		let textvalue = event.target.value.replace(/[^0-9]/g, '')
		if (textvalue === '') {
			this.setState({
				nbDisplay: textvalue !== '' ? textvalue : '',
				nbDisplayError: 'la valeur doit être un entier'
			})
		} else {
			if (xmlDoc.getElementsByTagName("NBUSE")[0]) {
				xmlDoc.getElementsByTagName("NBUSE")[0].childNodes[0].nodeValue = event.target.value ? event.target.value : 1
			}
			else {
				let newEle = xmlDoc.createElement("NBUSE")
				let newText = xmlDoc.createTextNode(event.target.value ? event.target.value : 1)
				newEle.appendChild(newText)
				xmlDoc.getElementsByTagName("RULES")[0].appendChild(newEle)
			}
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
		}


	}
	const checkInfiniNbUse = (checked) => {
		console.log(checked)
		let prom = promotion

		onPromotionUpdate(prom)
		setNbUse(checked ? -1 : 1)
		if (isCodeUnique) {
			setInfini(checked)
			prom.max = -1
			if (checked) {
				setNbUse(-1)
			} else {
				setNbUse(1)
			}
		}
		else setInfiniNbUse(checked)
		const serializer = new XMLSerializer()
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		if (xmlDoc.getElementsByTagName("NBUSE")[0]) {
			xmlDoc.getElementsByTagName("NBUSE")[0].childNodes[0].nodeValue = checked ? -1 : 1
		}
		else {
			let newEle = xmlDoc.createElement("NBUSE")
			let newText = xmlDoc.createTextNode(checked ? -1 : 1)
			newEle.appendChild(newText)
			xmlDoc.getElementsByTagName("RULES")[0].appendChild(newEle)
		}
		prom.rules = serializer.serializeToString(xmlDoc)
		onPromotionUpdate(prom)
	}
	const handleChangeNbCodeValue = (event) => {
		let parser = new DOMParser()

		const { promotion, onPromotionUpdate } = props
		CalculMaxCode(null, null)

		let prom = promotion
		setCanOpenGenerateVoucher(false)
		if (!isCodeUnique) prom.singleCode = ''
		if (!infini) {
			prom.max = event.target.value
		} else {
			prom.max = -1
		}
		onPromotionUpdate(prom)
		// if (xmlDoc.getElementsByTagName("PROPS")[0]) {
		// 	setCanOpenGenerateVoucher(false)

		// 	if(xmlDoc.getElementsByTagName("PROPS")[0].childNodes[0].getAttribute('NAME') ==='MAX' )	
		// 	{
		// 		xmlDoc.getElementsByTagName("PROPS")[0].childNodes[0].setAttribute("VALUE", event.target.value)
		// 		prom.properties = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
		// 	}else if(xmlDoc.getElementsByTagName("PROPS")[0].childNodes[1].getAttribute('NAME') ==='MAX' ){
		// 		if(infini){
		// 			xmlDoc.getElementsByTagName("PROPS")[0].childNodes[1].setAttribute("VALUE", -1)
		// 		}else{
		// 			xmlDoc.getElementsByTagName("PROPS")[0].childNodes[1].setAttribute("VALUE", event.target.value)
		// 		}
		// 		prom.properties = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
		// 	}

		// 	onPromotionUpdate(prom)
		// }
	}
	const handleChangeUniqueCodeValue = (event) => {
		const { promotion, onPromotionUpdate } = props
		setlenghtCode(event.target.value.length)
		let prom = promotion
		prom.singleCode = event.target.value
		prom.codeLength = event.target.value.length
		onPromotionUpdate(prom)
	}
	const handleChangeInfiniValue = () => {
		const { promotion, onPromotionUpdate } = props
		let prom = promotion
		setInfini(!infini)
		if (!infini) {
			setNbUse(-1)
			prom.max = -1
		} else {
			prom.max = -1
		}
		onPromotionUpdate(prom)

	}
	const handleClickGenerateNewCode = async () => {
		if (codeNumGenerate === '' || codeNumGenerate === 0)
			setCanGenerateVoucher(`Veuillez saisir le nombre de code à générer`)
		let valueToGenerate = isCodeUnique ? promotion.max : codeNumGenerate //(codeNumGenerate !== "" &&  ? remainingCode : codeNumGenerate)
		//setProgress(0.1)

		if (isCodeUnique && setInfiniNbUse) {
			await generateNewVoucherCodesApiCall(valueToGenerate)
		} else {
			if (parseInt(remainingCode) < parseInt(valueToGenerate)) {
				setCanGenerateVoucher(`Impossible de générer plus de ${remainingCode} codes`)
			} else {
				await generateNewVoucherCodesApiCall(valueToGenerate)
			}
		}
		//setCodeNumGenerate(0)

	}

	const updateProgress = (data, value) => {
		let refValue = parseInt(isVoucherExist.count) + parseInt(value)
		let p = ((parseInt(data.data) * 100) / parseInt(refValue)) <= 100 ? ((parseInt(data.data) * 100) / parseInt(refValue)) : 100
		setProgress(p)

	}
	const generateNewVoucherCodesApiCall = async (value) => {
		//handleStartAction()
		let numberCode = value <= 100000 ? 100 : 1000
		let occurence = Math.floor(value / numberCode)
		let remainder = value % numberCode
		if (occurence > 0) {
			for (var i = 1; i <= occurence; i++) {
				handleStartAction(Math.floor(i * numberCode / occurence))
				setProgress(Math.floor(i * numberCode / occurence))
				if (!continueGen || stopGenCode) {
					console.log('test stop')
					break
				}
				//   if (stopGenCode) {
				// 	throw new Error('Generation stopped');
				//   }
				else
					if (i === occurence) {
						await genCodes(numberCode, value)
					} else {
						await genCodes(numberCode, value)
					}
				// if (continueGen) {
				// 	if (i === occurence) {
				// 		await genCodes(numberCode, value)
				// 	} else {
				// 		await genCodes(numberCode, value)
				// 	}
				// }
			}
			if (remainder > 0) {
				await genCodes(parseInt(remainder), value)
			}
		} else {
			for (var i = 1; i <= value; i++) {
				setProgress(Math.floor(i * 100 / value))
			}
			await genCodes(parseInt(value), value)
		}
		checkVoucherCodesAvailable()
	}
	const genCodes = async (numberCode, value) => {
		return new Promise(async (resolve, reject) => {
			const generateOptions = { Number: parseInt(numberCode), PromoId: promotion.id }
			let abortHandler = () => {
				console.log('aborted')
				reject('Aborted')
			}
			if (abortController.signal.aborted) return

			if (stopGenCode) {
				abortController.signal.addEventListener('abort', abortHandler)
			}
			// abortController.signal.addEventListener("click", () => {
			// 	abortController.signal.abort();
			// 	console.log("Download aborted");
			//   }) 
			else if (continueGen) {
				abortController.signal.addEventListener('abort', abortHandler)
				PromotionsVouchersApi.generateVoucherCode(generateOptions).then((data) => {
					console.log(data)
					abortController.signal.removeEventListener('abort', abortHandler)
					// updateProgress(data, value)
					resolve()
				})
					.catch((error) => {
						console.log(error)
						reject('Aborted')
					})
			}

		})
		// return new Promise(async (resolve, reject) => {
		// 	const generateOptions = { Number: parseInt(numberCode), PromoId: promotion.id };

		// 	let abortHandler = () => {
		// 	  console.log('aborted');
		// 	  reject('Aborted');
		// 	};
		// 	const executeCodeGeneration = async () => {
		// 		try {
		// 		  abortController.signal.addEventListener('abort', abortHandler);

		// 		  while (continueGen) {
		// 			const data = await PromotionsVouchersApi.generateVoucherCode(generateOptions);
		// 			console.log(data);

		// 			updateProgress(data, value);
		// 		  }

		// 		  // Resolve the Promise when continueGen becomes false
		// 		  resolve();
		// 		} catch (error) {
		// 		  console.log(error);
		// 		  reject('Aborted');
		// 		} finally {
		// 		  abortController.signal.removeEventListener('abort', abortHandler);
		// 		}
		// 	  };



		// 	  executeCodeGeneration();
		//   })
	}
	const handlePauseGeneration = () => {
		setContinueGen(false)
		setStopGenCode(true)
	}

	const handleResumeGeneration = () => {
		setContinueGen(true)
		setStopGenCode(false)
	}
	const checkVoucherCodesAvailable = () => {
		const { promotion } = props

		if (promotion.max) {
			Vouchers.countLines(promotion.id).then(response => {
				response.data > 0 ? onPromotionVoucherExist({ exist: true, count: response.data }) : onPromotionVoucherExist({ exist: false, count: 0 })
			})
		}
	}

	const hangleChangeGenDateStart = (updatedDateStart) => {
		const dateStart = new Date(Object.values(updatedDateStart).slice(0, 10).join(''))

		if (dateStart instanceof Date && !isNaN(dateStart)) {
			setGenDateStart(Object.values(updatedDateStart).slice(0, 10).join(''))
		} else {
			setGenDateStart("")
		}
	}

	const hangleChangeGenDateEnd = (updatedDateEnd) => {

		const dateEnd = new Date(Object.values(updatedDateEnd).slice(0, 10).join(''))

		if (dateEnd instanceof Date && !isNaN(dateEnd)) {
			setGenDateEnd(Object.values(updatedDateEnd).slice(0, 10).join(''))
		} else {
			setGenDateEnd("")
		}
	}
	const handleClickOpenJobs =() =>{
		window.open(`${process.env.PUBLIC_URL}/#/JobHistory?refid=${promotion.id}&typeid=Null&objectid=6`, '_blank')

	}
	// const handleOpenAlert = () => {
	// 	setReturnedVoucherCodeGenerate(false)
	// }
	const handleSetCodeUnique = () => {
		const { promotion } = props
		let prom = promotion
		if (isCodeUnique) {
			prom.singleCode = ''
			prom.prefix = ''
		}
		// if(create ){
		// 	if( !isCodeUnique ) prom.properties ='<PROPS><PROP NAME="SINGLECODE" VALUE="" /><PROP NAME="MAX" VALUE="" /><PROP NAME="LENGTH" VALUE="" /></PROPS>'
		// 	if( isCodeUnique ) prom.properties ='<PROPS><PROP NAME="MAX" VALUE=""/><PROP NAME="LENGTH" VALUE=""/><PROP NAME="PREFIX" VALUE=""/></PROPS>'
		// }
		onPromotionUpdate(prom)
		setCodeUnique(!isCodeUnique)
		//CalculMaxCode(promotion.codeLength,promotion.prefix)

	}
	if (promotion.typeid === 1 && promotion.properties === "") {
		let prom = promotion
		prom.properties = '<PROPS><PROP NAME="MAX" VALUE=""/><PROP NAME="LENGTH" VALUE=""/><PROP NAME="PREFIX" VALUE=""/></PROPS>'
		onPromotionUpdate(prom)
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			searchClick()
		}
	}

	const handleOpenUrl = (url, type) => {
		window.open(url, "_blank")

		// fetch(url, { method: "GET" })
		//   .then((response) => {
		// 	if (response.ok) {
		// 	  // URL is valid, open it in a new window
		// 	  window.open(url, "_blank")
		// 	} else {
		// 	  // URL is not valid, show error message
		// 	  throw new Error("Invalid URL")
		// 	}
		//   })
		//   .catch((error) => {
		// 	console.error(error)
		// 	// Show error message to the user
		// 	alert("Failed to open URL: " + error.message)
		//   })
	}
	//get voucher informations located in <PROPS> .... </PROPS>
	// let parser = new DOMParser()
	// let xmlDoc = parser.parseFromString(promotion.properties, "text/xml")
	let prefix = ''
	let availableCode = ''
	let remainingCode = 0

	prefix = <CustomTextField label="Préfix codes" fullWidth={true} value={promotion.prefix} handleChange={handleChangePrefixValue} create={create} />
	if (isVoucherExist.exist) {
		//if (xmlDoc.getElementsByTagName("PROPS")[0]) {
		availableCode = isVoucherExist.count
		remainingCode = promotion.max - isVoucherExist.count
		//}
	} else {
		availableCode = 0
		remainingCode = promotion.max

	}
	const options = {
		rowsPerPage: 10,
		rowHover: false,
		textLabels: {
			body: {
				noMatch: 'Aucune ligne trouvée',
				toolTip: 'Trier'
			},
		},
		setTableProps: () => {
			return {
				padding: 'normal',
				size: 'small',
			}
		},
		selectableRows: 'none',
		resizableColumns: false,
		print: false,
		search: false,
		download: false,
		filter: false,
		viewColumns: false,
		fixedSelectColumn: true,
		customToolbar: false,
		pagination: true
	}

	const columns = [
		{
			name: 'id',
			label: 'Identifiant',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', width: '20em' } }),
				filter: false,
				setCellProps: () => ({ style: { paddingLeft: '10px', whiteSpace: "pre", fontSize: '.8em' } }),
			}
		},
		{
			name: 'voucher',
			label: 'Voucher',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', width: '30em' } }),
				filter: false,
				setCellProps: () => ({ style: { fontSize: '.8em' } }),
			}
		}, {
			name: 'createdon',
			label: 'Date de génération',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000' } }),
				filter: false,
				setCellProps: () => ({ style: { width: '100em', fontSize: '.8em' } }),
				customBodyRender: (value) => {
					return (
						value != null
							? (<div>
								{DateFormatter.getDayNameDateHourMinSec(value)}
							</div>)
							: null
					)
				}
			}
		},
	]

	return (
		<Grid item xs={12} style={{ marginTop: '.5em' }}>
			{/* { returnedVoucherCodeGenerate ? (<CustomAlert libelle="Codes générés" severity="success" open={returnedVoucherCodeGenerate} setOpen={handleOpenAlert} />) : null } */}

			{/* <Grid container direction="row">
			</Grid> */}
			<Grid container spacing={8} direction="row" justify="space-between" >

				<Grid item md={8} xs={8}>
					<Grid container direction="row">
						<LocalActivityIcon /><Typography variant="title" style={{ marginLeft: '.5em', marginBottom: '1em' }}>Voucher</Typography>
					</Grid>
					{(availableCode === 0 || isCodeUnique) && <Grid container spacing={1}>
						<Grid item md={12} xs={12}>
							<Grid container spacing={1}>
								<Grid item md={2} sm={2} xs={12}>
									<CustomSwitch
										checked={isCodeUnique}
										onChange={handleSetCodeUnique}
										color="primary" size="small"
										inputProps={{ 'aria-label': 'primary checkbox' }}
										disabled={!create || availableCode !== 0}
									/>
									<Typography variant="title" style={{ marginLeft: '.5em', marginTop: '15px' }}>Code Unique</Typography>
								</Grid>



							</Grid>
						</Grid>
					</Grid>}
					{(!isCodeUnique) &&
						<Grid container spacing={1}>
							<Grid container spacing={1}>
								<Grid item md={12} xs={12} justify="flex-start">
									<Grid container spacing={1} justify="flex-start">
										<Grid item md={4} xs={12}>
											<CustomTextField fullWidth={true} label="Longueur des codes (entre 4 et 16)" value={promotion.codeLength} type="number" handleChange={handleChangeCodeSizeValue} create={create}
												errorLabel={lenghtCode < 4 || lenghtCode > 16 ? 'Longueur de code incorrecte' : ''} />
										</Grid>
										<Grid item md={4} xs={12}>
											{prefix}
										</Grid>
										<Grid item md={4} xs={12}>

											<Grid container spacing={3} justify="flex-start" direction="row">
												<Grid item xs={8}>
													<CustomTextField fullWidth={true} label="Nombre de saisies" value={nbUse} type="number" handleChange={handleChangeNbUseValue} create={create} readOnly={infininbUse}
														errorLabel={nbUse > 0 || (nbUse === -1 && infininbUse) ? null : "Nombre de saisies doit être supérieur à 1"}
													/>
												</Grid>
												<Grid item xs={4}>
													<Tooltip title="Infini"
														placement="bottom-start"
														TransitionComponent={Zoom}
													>
														<div style={{ paddingTop: "15px" }} >
															<CustomSwitch
																checked={infininbUse}
																disabled={!create}
																color="primary" size="small"
																inputProps={{ 'aria-label': 'primary checkbox' }}
																onChange={(e, check) => checkInfiniNbUse(check)}
																className={classes.switchSelectAll}
															/>
															Infini
														</div>
													</Tooltip>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item md={12} xs={12}>
									<Grid container spacing={1}>
										<Grid item md={4} sm={4} xs={12}>
											<CustomTextField label="Nombre de codes total" value={promotion.max} handleChange={handleChangeNbCodeValue} type="number"
												create={create} errorLabel={controlField && controlField.voucher !== "" ? controlField.voucher : null || maxCodeVoucherNumber < promotion.max ? 'Nombre maximal de code est estimé à ' + maxCodeVoucherNumber : null} fullWidth={true} />
										</Grid>
										<Grid item md={4} sm={4} xs={12}>
											<CustomTextField label="Codes disponibles" value={availableCode} fullWidth={true} />
										</Grid>
										<Grid item md={4} sm={4} xs={12}>
											<CustomTextField label="Codes restants" value={remainingCode} fullWidth={true} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					}
					{(isCodeUnique) &&
						<Grid container spacing={1}>
							<Grid item md={12} xs={12}>
								<Grid container spacing={1}>

									<Grid item md={4} sm={4} xs={12}>
										<CustomTextField fullWidth={true} label="Code unique (Longueur entre 4 et 16)" value={promotion.singleCode} handleChange={handleChangeUniqueCodeValue} create={create}
											//readOnly={availableCode !== 0}
											errorLabel={(lenghtCode < 4 || lenghtCode > 16) ? 'Longueur de code incorrecte' : null} />
									</Grid>

									<Grid item md={3} sm={3} xs={12}>
										{/* <CustomTextField label="Nombre de codes total" type="number" value={infini ? -1 : promotion.max}
											handleChange={handleChangeNbCodeValue}
											errorLabel={controlField && controlField.voucher !== "" ? controlField.voucher : null}
											readOnly={infini} create={create} fullWidth={true} /> */}
										<CustomTextField fullWidth={true} label="Nombre de saisies" value={nbUse} type="number" handleChange={handleChangeNbUseValue} create={create}
											errorLabel={nbUse > 0 || (nbUse === -1 && infini) ? null : "Nombre de saisies doit être supérieur à 1"}
											readOnly={infini} />
									</Grid>
									<Grid item md={3} sm={3} xs={12}>


										<Tooltip title="Infini"
											placement="bottom-start"
											TransitionComponent={Zoom}
										>
											<div style={{ paddingTop: "15px" }} >
												<CustomSwitch
													checked={infini}
													color="primary" size="small"
													inputProps={{ 'aria-label': 'primary checkbox' }}
													onChange={(e, check) => checkInfiniNbUse(check)}
													className={classes.switchSelectAll}
													disabled={!create}
												/>
												Infini
											</div>
										</Tooltip>
									</Grid>
									{/* <Grid item md={3} sm={3} xs={12}>
										<CustomTextField label="Codes disponibles" value={availableCode} fullWidth={true} />
									</Grid>
									{!infini && <Grid item md={3} sm={3} xs={12}>
										<CustomTextField label="Codes restants" value={remainingCode} fullWidth={true} />
									</Grid>} */}
								</Grid>
							</Grid>
						</Grid>
					}
				</Grid>

				<Grid item md={4} sm={4} xs={4} style={{ marginTop: '-35px' }}>
					{type !== 'create' && voucherInfoExist && !isCodeUnique ?
						<Grid item md={12} sm={12} xs={12}>
							<Button color="primary" variant="contained" disabled={remainingCode === 0 || !canOpenGenerateVoucher || promotion.closed || create} className={classes.button} onClick={handleClickOpenGenerate}>
								<SyncIcon style={{ marginRight: '.5em' }} />GENERER
							</Button>
						</Grid>
						: null
					}
					{type !== 'create' ?
						<Grid item md={12} sm={12} xs={12}>
							<Button variant='contained' color="primary" className={classes.button} onClick={handleClickOpen}><VisibilityIcon style={{ marginRight: '.5em' }} />Voir la liste</Button>
						</Grid>
						: null
					}

					<Grid item md={12} sm={12} xs={12}>
						<Tooltip title="Télécharger tous les vouchers">
							<Button variant='contained' color="primary" className={classes.button} onClick={() => exportToCsv()} disabled={promotion.max === remainingCode}>
								<CloudDownloadIcon style={{ marginRight: '.5em' }} />TELECHARGER
								{csvLoading ?
									(<CircularProgress color="secondary" style={{ display: 'block', width: "25px", height: "25px", marginLeft: ".5em" }} />)
									: null
								}
							</Button>
						</Tooltip>
					</Grid>
					<Grid item md={12} sm={12} xs={12} style={{marginBottom: '.5em'}}>
						<Button color="primary" variant="contained" className={classes.buttonHapi} onClick={() => handleOpenUrl(promoHAPI, 'promoHAPI')} ><VisibilityIcon style={{ marginRight: '.5em' }} />TEST PROMOTION HAPI</Button>
						<Button color="primary" variant="contained" className={classes.button} onClick={() => handleOpenUrl(promoVoucherHAPI, 'promoVoucherHAPI')}><VisibilityIcon style={{ marginRight: '.5em' }} />TEST VOUCHER HAPI</Button>

					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button color="primary" variant="contained" disabled={create} onClick={handleClickOpenJobs} className={classes.button} style={{ marginTop: '.5em' }} ><HistoryIcon
							 style={{ marginRight: '.5em' }} />Jobs</Button>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<Button color="primary" variant="contained" className={classes.button} onClick={handleClickOpenProperty} ><VisibilityIcon style={{ marginRight: '.5em' }} />VOIR PROPRIETES</Button>
					</Grid>
				</Grid>
			</Grid>
			<ThemeProvider theme={getMuiTheme()}>
				<Dialog onClose={handleClose}
					aria-labelledby="customized-dialog-title"
					open={open}
					TransitionComponent={Transition}
					maxWidth="lg"

				>
					<div className={classes.dialogHeader}>
						VOUCHER
						<Button onClick={handleClose} className={classes.closeIcon} >
							<CloseIcon />
						</Button>
					</div>
					<DialogContent dividers style={{ paddingTop: '.5em', paddingBottom: '.2em' }}>
						<Paper elevation={3} style={{ height: '90px', margin: '5px' }} >

							<div style={{ display: 'flex', alignItems: 'center' }}>
								{/* <TextField
								placeholder="Rechercher par code"
								onChange={onChangeSearch} variant="outlined"
								size="small" handleKeyPress={handleKeyPress}
								/> */}
								<div style={{ marginTop: '0.8em' }}>
									<CustomTextField label="Rechercher par code" value={vouchersearch} fullWidth="true"
										handleChange={onChangeSearch} handleKeyPress={handleKeyPress} create={true}
										errorLabel={codeSearchVoucherError ? "Veuillez saisir au moins 3 caractères" : null}
									/>
								</div>
								<div>
									<DatePickerCanal label="Du" source="genDateStart"
										required={false} dateOnly shrink={true}
										onChange={hangleChangeGenDateStart}
										value={genDateStart}
									/>
								</div>
								<div style={{ marginLeft: ".5em" }}>
									<DatePickerCanal label="au" source="genDateEnd"
										required={false} dateOnly shrink={true}
										onChange={hangleChangeGenDateEnd}
										value={genDateEnd}
									/>
								</div>
								<IconButton type="button"
									aria-label="search"
									onClick={() => searchClick()}
									color="primary"
									className={classes.customSearchButtonIcon}
									disabled={!searchVoucher}>
									<SearchIcon />
								</IconButton>
								{isLoading ? (<CircularProgress size="1.8em" style={{ position: 'absolute', top: '50%', left: '50%' }} />) : null
								}

								<div style={{ marginLeft: '8%', marginRight: '15px' }}>
									<Tooltip title="Télécharger la liste">
										<Button variant={'contained'} className={classes.buttonModal} color={'primary'}
											onClick={() => exportToCsv(true)} disabled={searchVoucher.length <= 0} style={{ position: "inherit", right: "1.2em", top: "4.58em" }}>
											<CloudDownloadIcon style={{ marginRight: '.5em' }} /> TELECHARGER
											{csvLoading ?
												(<CircularProgress color="secondary" style={{ display: 'block', width: "25px", height: "25px", marginLeft: ".5em" }} />)
												: null
											}
										</Button>
									</Tooltip>
								</div>
								<div>
									<Button variant={'contained'} className={classes.buttonModal} color={'primary'}
										onClick={() => searchClick('ShowAll')} style={{ position: "inherit", right: "14em", top: "4.58em" }}>
										AFFICHER TOUS
									</Button>
								</div>
							</div>
						</Paper>
						<div style={{ paddingTop: '8px' }}>
							<Paper elevation={3} >

								<ThemeProvider theme={getMuiTheme()}>
									{searchVoucher ?
										(<MUIDataTable data={searchVoucher}
											columns={columns} options={options} />)
										: null
									}
								</ThemeProvider>
							</Paper>

						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="secondary" variant="contained" className={classes.buttonCancel}>
							<CancelIcon className={classes.leftIcon} />
							Annuler
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
			<CustomDialog info="ALERT" handleClose={handleCloseAlert} open={openAlert} method={handleStopGenerateCodes}
				content="Attention, si vous validez cette action, la génération des codes vouchers sera arrêtée" />

			<Dialog onClose={handleGenerateClose}
				maxWidth="sm"
				fullWidth
				aria-labelledby="customized-dialog-title"
				open={openGenerate}
				TransitionComponent={Transition}
				disableEscapeKeyDown={true}
				disableBackdropClick={true}
			>
				<div className={classes.dialogHeader}>
					GENERER
					<Button onClick={handleGenerateClose} className={classes.closeIcon} >
						<CloseIcon />
					</Button>
				</div>
				<DialogContent dividers style={{ paddingTop: '.5em', paddingBottom: '.2em' }}>
					<div>
						<span>Combien de code souhaitez-vous générer ?</span>
					</div>
					<div style={{ marginTop: '1em' }}>
						<TextField
							placeholder="Nombre de code" disabled={isCodeUnique}
							onChange={onChangeNumberGenerate} variant="outlined"
							size="small" defaultValue={infini ? 0 : remainingCode}
							helperText={canGenerateVoucher} error={canGenerateVoucher !== "" ? true : false}
						/>
						<Button color="primary" variant="contained" disabled={progress !== 0}
							className={classes.buttonModalGenerate} onClick={handleClickGenerateNewCode}>
							<SyncIcon style={{ marginRight: '.5em' }} />GENERER
						</Button>
						<div className={classes.info}>
							<InfoIcon className={classes.infoLogo} color="action" fontSize="small" />
							<span className={classes.infoText}>Nombre maximum de codes pouvant être générés : <b>{infini ? 'Infini' : remainingCode}</b></span>
						</div>
						<Grid container>
							<Grid item xs={10}>
								<LinearProgressWithLabel value={progress} />
							</Grid>
							{/* {!stopGenCode ?
								<div style={{ marginTop: '-15px' }} >
									<Grid item xs={1}>

										<IconButton type="button"
											aria-label="search"
											onClick={() => handlePauseGeneration()}
											color="primary"
											disabled={progress === 0 || progress === 100}
											className={classes.infoLogo}
										// className={classes.customSearchButtonIcon}
										>
											<PauseCircleOutlineIcon />
										</IconButton>
									</Grid>
								</div> :
								<div style={{ marginTop: '-15px' }} >
									<Grid item xs={1}>
										<IconButton type="button"
											aria-label="search"
											onClick={() => handleResumeGeneration()}
											color="primary"
											disabled={progress === 0 || progress === 100}
											className={classes.infoLogo}
										// className={classes.customSearchButtonIcon}
										>
											<PlayCircleFilledIcon />
										</IconButton>
									</Grid>
								</div>
							} */}


						</Grid>

						{progress > 0 && progress < 100 && <div style={{ color: 'red' }}> Génération des codes en cours, veuillez ne pas quitter cette page ... </div>}
						{/* { isLoading ? (<CircularProgress size="1.8em" style={{marginLeft: '.5em', marginRight: '.5em'}}/>) : null} */}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleGenerateClose} color="secondary" disabled={progress > 0 && progress < 100} variant="contained" className={classes.buttonCancel}>
						<CancelIcon className={classes.leftIcon} />
						FERMER
					</Button>
				</DialogActions>
			</Dialog>
			{/* <CustomDisplayDialog info="Promotion HAPI" handleClose={()=>setOpenPromoHAPI(false)} open={openPromoHAPI} content={getPromoHAPI()} />					 */}
			<CustomDisplayDialog info="proprietes" handleClose={handleCloseProperty} open={openProperty} content={debugProperty()} />
			{/* <CustomDisplayDialog info="Voucher HAPI" handleClose={() => setOpenVoucherHAPI(false)} open={openVoucherHAPI} content={getVoucherHAPI()} /> */}

		</Grid>
	)
}


const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null,
	isVoucherExist: state.reducer.Promotion.isVoucherExist,
	controlField: state.reducer ? state.reducer.Promotion.controlField : null,
})

const mapDispatchToProps = ({
	onPromotionUpdate,
	onGetPromo,
	onPromotionVoucherExist
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PromotionsVouchers)
