import React, { Component } from 'react'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomTextField, CustomSelect, CustomDisplayDialog, CustomAlert ,CustomCheckBox,CustomFooterList} from '../../layout/GlobalStyles'
import { DomainsItems } from '../../../../src/tools'
import GestionFields from '../../common/fields/GestionFields'
import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { ImageField } from '../../common/fields'
import { Url } from '../../../tools'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import { AlbumsPictures, CompaniesAlbums } from '../../../requests'
import { Badge, Tooltip } from '@material-ui/core'
import ListSubheader from '@material-ui/core/ListSubheader'
import AlbumInfoGlobal from './AlbumInfoGlobal'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DeleteIcon from '@material-ui/icons/Delete'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import AssistantButton from '../../common/fields/AssistantButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { CloseIcon, AddIcon, CheckIcon, CloudUploadIcon } from '../../../constants/icons'
import { Links } from '../../../tools'
import DropZoneGlobal from '../../tools/DropZoneGlobal'
import { OnAlbumPictureUpdate,OnLoadNewImage,SetIsImagePivot } from '../../../actions/companiesAlbums'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import StarIcon from '@material-ui/icons/Star'
import Avatar from '@material-ui/core/Avatar'
// import CameraIcon from '@material-ui/icons/Camera'
import medalicon from '../../../pictures/medalicon.png'
import medalpivoticon from '../../../pictures/medalpivoticon.png'

import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';
const styles = theme => ({
	root:{
		opacity: '0.5'
	},
	container: {
		display: 'flex',

	},
	item: {
		marginRight: '1rem',
		marginLeft: '5px'
	},
	formControl: {
		margin: '.5em .5em 1.5em 2.2em',
		minWidth: '230px',
		height: '25px'
	},
	customSelect: {
		//position: 'absolute',
		top: '8px',
		margin: theme.spacing(1),
		padding: '6px 8px',
		maxWidth: '70px',
		minHeight: '40px',
		backgroundColor: '#fff',
		borderRadius: '4px',
	},
	cardPaddingMargin: {
		marginRight: '20px'
	},
	button: {
		margin: theme.spacing(1),
		marginTop: '0px',
		backgroundColor: '#288DB1',
		//width: '150px',
		maxHeight: '45px',
		fontSize: '0.75rem',
		top: '45px',
		lineHeight: '1.2em',
		'&:hover': {
			background: '#1c627b'
		}
	},
	listContainer: {
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		maxHeight: 'calc(70vh - 110px)',
		zIndex: '10'

	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
	frame:{
		border: '2px solid #7937c1',
		//padding: '20px',
		width: '37px'
		 
	}
})
const IconOption = (props) => (
	// <Option {... props}>
	  <div  style={{paddingTop:'20px'}}>
		<Tooltip title='Pivot'>
		<img src={medalpivoticon} height={'20px'} width={'20px'}/>
		</Tooltip>
	  </div>
	//* </Option> */}
  )
const SmallAvatar = withStyles((theme) => ({
	root: {
	  width: '22px',
	  height: '22px',
	  //border: `2px solid ${theme.palette.background.paper}`,
	},
  }))(Avatar)
class AlbumImageFiche extends Component {

	constructor(props) {
		super(props)
		this.state = {
			Albumspictures: [],
			AlbumsPictures: [],
			types: [],
			pivots: [{itemid: 0 , label:'Selectionner un pivot'}],
			imageToremove: {},
			selectedpicture: {
				id: '',
				companyid: 1,
				name:  props.record.name,
				countryid: -1,
				typeid: 0,
				filename: '',
				height: '',
				parentid: '',
				width: '',
				title: '',
				albumid: props.record.id
			},
			countries: [],
			searchValue: '',
			openAlertMessage: false,
			openAddFile: false,
			showError: false,
			show: false,
			replaceFile: false,
			serchPivot: 0
		}
	}
	OnchangeParent = (event) => {
		this.setState({ selectedPerentId: event.target.value })
	}
	getalbumsType = () => {
		return Object.values(DomainsItems.getItems(8).filter(s => !s.disabled))
	}
	// getcountry = () => {
	// 	let res = Object.values(DomainsItems.getItems(2).filter(s => s.filter))
	// 	res.push({ itemid: -1, label: 'Default' })
	// 	return res.sort((i, j) => i.itemid < j.itemid ? -1 : 1)
	// }
	componentDidMount() {
		const { record, type, create, albumPicture,OnAlbumPictureUpdate } = this.props
		let types = this.getalbumsType()
		let res = this.state.pivots
		this.getalbumsType().filter(s=>s.isPivot).forEach((elt) => 
			res.push(elt))
  		this.setState({pivots: res})
		AlbumsPictures.getById(record.id).then((json) => {
			if (json.data !== undefined) {
				this.setState({ Albumspictures: json.data })
				this.setState({ AlbumsPictures: json.data })
				json.data.forEach(elt =>{
					if(types.find(t=>t.itemid === elt.typeid))
						types.filter(t=>t.itemid === elt.typeid)[0].disabled = true
					if(elt.typeid ===13)
					 this.setState({selectedpicture : elt}) 
				})
 				

			}
		})
		this.setState({ types: types })
		// this.setState({ countries: this.getcountry() })
		if(type ==='edit' && create)
			this.setState({selectedpicture : albumPicture}) 
		if(type === 'create')	{
			let picture = this.state.selectedpicture
			picture.title = record.name
			this.setState({selectedpicture : picture}) 
			OnAlbumPictureUpdate(picture)
		}


	}
	componentDidUpdate(prevProps,prevState){
		const { record,image, create,OnAlbumPictureUpdate } = this.props

		if(image !== prevProps.image){
			let types = this.getalbumsType()
			let picture = this.state.selectedpicture
			picture.filename = image.name
			picture.height = image.height
			picture.width = image.width
			if (create)
			this.setState({selectedpicture : picture})
			OnAlbumPictureUpdate(picture)
			AlbumsPictures.getById(record.id).then((json) => {
				if (json.data !== undefined) {
					this.setState({ Albumspictures: json.data })
					this.setState({ AlbumsPictures: json.data })
					json.data.forEach(elt =>{
						if(types.find(t=>t.itemid === elt.typeid))
							types.filter(t=>t.itemid === elt.typeid)[0].disabled = true
					})
				}
			})
		}

	}
	handlePictureClick(event, select) {
		const {SetIsImagePivot,OnAlbumPictureUpdate} = this.props

		this.setState({ selectedpicture: select })
		OnAlbumPictureUpdate(select)
		if(this.state.types.find(s=>s.itemid === select.typeid).isPivot === 1)
			SetIsImagePivot(true)
		else 
			SetIsImagePivot(false)
	}

	handleChangeGeneralItem = (event, field) => {
		const {OnAlbumPictureUpdate,albumPicture} = this.props
			let picture = this.state.selectedpicture
			picture.companyid = 1
			if (field === 'name') picture.name = event.target.value
			if (field === 'titre') picture.title = event.target.value
			if (field === 'filename') {		
				picture.filename = event.target.value
 			}
			this.setState({ selectedpicture: picture })
			OnAlbumPictureUpdate(picture)
	}
	handleChangeTypeItem = (e) =>{
		const {OnAlbumPictureUpdate} = this.props
		let newtype = this.state.types.filter(s=>s.itemid ===e.target.value)[0]
 		let picture = this.state.selectedpicture
		picture.typeid = newtype ? newtype.itemid : -1
		picture.parentid = newtype ? newtype.parentid : ''
 		this.setState({selectedpicture: picture})
		OnAlbumPictureUpdate(picture)
	}
	handleChangeInput = (event, type) => {
		this.setState({ searchValue: event.target.value })
		if (type === 'reset') {
			this.setState({ searchValue: '' })
			if(this.state.serchPivot === 0)
				this.setState({ Albumspictures: this.state.AlbumsPictures })
			else{
				let res = []
				let typeSelectParent = this.state.types.filter(s=>(s.parentid === this.state.serchPivot) || (s.isPivot && s.itemid === this.state.serchPivot))
				typeSelectParent.forEach(element => {
					if(this.state.AlbumsPictures.find(s=>s.typeid === element.itemid ))
						res.push(this.state.AlbumsPictures.filter(s=>s.typeid === element.itemid )[0])
				})
				this.setState({Albumspictures : res})
			}
				
		}

	}
	searchImage = () => {
		let pictureList = this.state.AlbumsPictures
		if(this.state.serchPivot ===0 && this.state.searchValue==='') {
			this.setState({Albumspictures : this.state.AlbumsPictures })
			return
		}
		else
			if (this.state.types && pictureList && this.state.searchValue !== '') {
				let typePivots = this.state.types
				if(this.state.serchPivot !==0){
					let result = []
					this.state.types.forEach((elt)=>{
						if(elt.isPivot && elt.itemid === this.state.serchPivot || elt.parentid === this.state.serchPivot)
						result.push(elt)
					})
					typePivots = result
				}
				let res = []
				typePivots.forEach(element => {
					
					if (element.label.toUpperCase().includes(this.state.searchValue.toUpperCase()))
						if (pictureList.find(s => s.typeid === element.itemid))
							res.push(pictureList.filter(s => s.typeid === element.itemid)[0])
				})
				if (pictureList.find(s => s.typeid.toString() === this.state.searchValue))
					res.push(pictureList.filter(s => s.typeid.toString() === this.state.searchValue)[0])

				this.setState({ Albumspictures: res })
			}
		
	}
	SetopenAlertMessage = (event, value) => {
		this.setState({ imageToremove: value })
		this.setState({ openAlertMessage: true })
	}
	handleCloseErrorMessage = () => {
		this.setState({ openAlertMessage: false })
	}
	handleRemoveImageClick = () => {
		const { record } = this.props
 		// AlbumsPictures.RemoveImage(this.state.imageToremove.id)
		new Promise((resolve, reject) => {
			AlbumsPictures.RemoveImage(this.state.imageToremove.id)
				.then((data) => {
					AlbumsPictures.getById(record.id).then((json) => {
						if (json.data !== undefined) {
							this.setState({ Albumspictures: json.data })
							this.setState({ AlbumsPictures: json.data })
						}
					})
					this.setState({ show: true,openAlertMessage: false  })
					resolve()
				}
				).catch((error) => {
					reject()
					this.setState({ showError: true,
						openAlertMessage: false })

					const timeId = setTimeout(() => {
						this.setState({ showError: false })

					}, 3000)
					return () => {
						clearTimeout(timeId)
					}
				})
		})
	}
	getMuiTheme = () => createTheme({
		overrides: {
			MuiBox: {
				root: {
					paddingTop: '5px'
				}
			},
			MuiGrid: {
				container: {
					paddingBottom: '0'
				}
			},
			MuiTypography: {
				root: {
					// margin: '25px',
					// fontSize: 'larger',
					// color: '#288db1'
				}
			},
			MuiCardContent: {
				root: {
					padding: '4px',
					'&:last-child': {
						paddingBottom: '4px',
					},
					'&:first-child': {
						paddingTop: 0,
						paddingBottom: 0,
						paddingLeft: 8,
						paddingRight: 8,
					},
				}
			},
			MuiFormLabel:{
				root:{
					"&$focused": {
						"color": "#288DB1"
					},
				}
			},
			MuiOutlinedInput: {
				multiline: {
					width: '480px'
				},
				'root': {  // - The Input-root, inside the TextField-root
					'&:hover fieldset': {
						borderColor: '#288DB1 !important', // - Set the Input border when parent has :hover
					},
					'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
						borderColor: '#288DB1 !important',
					},
				},
			},
			MuiButton: {
				textPrimary: {
					color: '#fff'
				}
			},
			MuiTypography:{
				body1:{
					fontSize: '0.8rem'
				}
			},
			MuiDialog:{
				paperWidthSm:{
					maxWidth:'1000px'
				}
			},
			MuiBackdrop :{
				opacity : '0.5',
				root:{
					opacity: '0.5'
				}}
			,
			MuiMenu:{
				paper:{
					maxHeight: 'calc(100% - 500px)'
				}
			},
			MuiBadge:{
				anchorOriginTopLeftRectangl:{
					transform : 'scale(1) translate(-30%, 0%)'
				}
			},
			MuiListSubheader :
			{ 
				sticky: {
				top: 0,
				zIndex: 10,
				position: 'sticky',
				backgroundColor: 'inherit'
				}
			}
			 
			
			
			



		},
	})
	openLink = (url) => (
		window.open(url, '_blank')
	)
	openDropZoneDialog = () => {
		this.setState({ openAddFile: true })
	}
	closeDropZoneDialog = () => {
		this.setState({ openAddFile: false })

	}
	handleOpenAlert = () => {
		this.setState({ show: false })
		this.setState({ showError: false })
	}
	handleChangePivot = (e)=>{
		this.setState({serchPivot : e.target.value})
		if(e.target.value ===0 && this.state.searchValue==='') {
			this.setState({Albumspictures : this.state.AlbumsPictures })
			return
		}
		let res = []
		let typeSelectParent = this.state.types.filter(s=>(s.parentid === e.target.value) || (s.isPivot && s.itemid === e.target.value))
		typeSelectParent.forEach(element => {
			if(this.state.searchValue && this.state.searchValue !==''){
				if(this.state.AlbumsPictures.find(s=>s.typeid === element.itemid && element.label.toUpperCase().includes(this.state.searchValue.toUpperCase())))
				res.push(this.state.AlbumsPictures.filter(s=>s.typeid === element.itemid && element.label.toUpperCase().includes(this.state.searchValue.toUpperCase()) )[0])
			}else
			if(this.state.AlbumsPictures.find(s=>s.typeid === element.itemid ))
				res.push(this.state.AlbumsPictures.filter(s=>s.typeid === element.itemid )[0])
		})
		this.setState({Albumspictures : res})

	}

	render() {
		const { record, classes, create, type,albumPicture, OnAlbumPictureUpdate,image,OnLoadNewImage,isImagePivot} = this.props
		let imgStyle = 'imagesAlbums'
		if (this.state.selectedpicture.width !== '' && this.state.selectedpicture.width <= 200 && this.state.selectedpicture.height <= 200) imgStyle = 'initial'
		return (
			<div>
				<ThemeProvider theme={this.getMuiTheme()}>
					{this.state.show ? (<CustomAlert libelle="Image supprimé" severity="success" open={!this.state.showError} setOpen={this.handleOpenAlert} />) : null}
					{this.state.showError ? (<CustomAlert libelle="Erreur lors de la suppression" severity="error" open={this.state.showError} setOpen={this.handleOpenAlert} />) : null}
					<Grid container spacing={12} direction="row" justify="space-between" >
						<Grid container direction="row" xs={12}>

							<Grid container>

								<Grid item xs={4}>
									{/* <GlobalListComponent  searchValue ={this.state.searchValue} classes={classes} 
									listItem={this.state.Albumspictures} types={this.state.types} onChangeSelect={(event) => this.handlePictureClick(event)}/> */}

									<Card className={classes.cardPaddingMargin}>

										<List dense className={classes.listContainer}
											subheader={
												<ListSubheader >

													<Grid container direction="row">
														<Grid item xs='10'> 							
															<CustomSelect label="Pivots" propsType="domainItem" value={this.state.serchPivot} values={this.state.pivots} handleChange={(e) => this.handleChangePivot(e)} fullWidth="true" />
														</Grid>
														<Grid item xs='10'> 
														<CustomTextField label="Recherche par type ou typeId"  value={this.state.searchValue}  handleChange={e=>this.handleChangeInput(e, '')}  valueReset={(e) => this.handleChangeInput(e, 'reset')}
															create="true" handleKeyPress={e=>this.searchImage()} fullWidth="true" removeicon={true} /> 
														</Grid>
														<Grid item xs='2'> 
														<IconButton type="button" aria-label="search" onClick={() => this.searchImage()} style={{ marginRight: '20px' }}  >
															<SearchIcon />
														</IconButton>
														</Grid>
													</Grid>
													
												</ListSubheader>
											}
										>

											{this.state.Albumspictures.map((value) => {
												const valueType = this.state.types.find(s=>s.itemid === value.typeid)		
												return (
													<ListItem key={value} button
														disabled={type  && type !== 'edit' || (create && type === 'edit' && albumPicture.typeid !== value.typeid)}
														selected={this.state.selectedpicture === value}
														onClick={(event) => this.handlePictureClick(event, value)}>
															<ListItemAvatar 	style={{width: '37px',height: '50px'}}>
															<Badge
																	invisible={!valueType.isPivot}
																	//style={{paddingTop:'20px'}}
																	//color="secondary"
																	// overlap="circular"
																	anchorOrigin={{
																	vertical: 'top',
																	horizontal: 'left',
																	}}
																	//variant="dot"
																	badgeContent={<IconOption style={{color:'#43a0bf',maxHeight:'5px',maxWidth:'5px',}}/>}
																>
																<ImageField label={value.filename} source='filename'  styleImg={valueType.width > 1300 ? 'imageslarge' :'imagespeoples'} basePath='/Albumspictures' record={value}  />
															</Badge>
															</ListItemAvatar>

														<ListItemText id={value.typeid} primary={`${valueType ? valueType.label : ''} (${value.typeid})`} />
														{valueType.isPivot &&
														<ListItemSecondaryAction>
															<IconButton title='Supprimer' edge="end" aria-label="delete" onClick={(event) => this.SetopenAlertMessage(event, value)} disabled={(type && type !== 'edit') ||  (create && type === 'edit')}>
																<DeleteIcon />
															</IconButton>
														</ListItemSecondaryAction>
														}
														
													</ListItem>
												)
											})}
										</List>
										<CustomFooterList curent={this.state.Albumspictures.length} total={this.state.AlbumsPictures.length}/>

									</Card>
									<Grid container style={{ paddingTop: '10px' }}>
										<Grid item xs={4}>
											<AssistantButton record = {record} countries ={this.state.countries} types={this.state.types} assistanceTitle="l'album" listAlbumsPictures={this.state.AlbumsPictures}
											 image={image} OnLoadNewImage={OnLoadNewImage} objectId={2}/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={8}>
									<Grid container>
										<Grid item xs={8} direction="row" >
											<Grid item xs={12}>
												<AlbumInfoGlobal recordelt={record} create={false} />
											</Grid>
											<Grid container direction="row" xs={12}>
												<Grid item xs={6} sm={6}>
													<CustomTextField label="ID Image" create={false} value={this.state.selectedpicture.id} fullWidth="true" />
												</Grid>
												<Grid item xs={6} sm={6}>
													{(create) ?
														<CustomSelect label="Type" propsType="domainItem" values={this.state.types.filter(s => s.isPivot !== 1)} required={true}
														value={this.state.selectedpicture.typeid}  
														handleChange={(e) => this.handleChangeTypeItem(e)} width='35%' fullWidth="true" />
														:
														<CustomTextField label="Type" value={this.state.types.find(t => t.itemid === this.state.selectedpicture.typeid) ? this.state.types.find(t => t.itemid === this.state.selectedpicture.typeid).label : ''} width='35%' fullWidth="true" />
													}
												</Grid>
											</Grid>
											{/* {(create) ?
												<CustomSelect label="Type" propsType="domainItem" values={this.state.types.filter(s => s.isPivot !== 1)} required={true}
												value={this.state.types.find(t => t.itemid === this.state.selectedpicture.typeid) ? this.state.types.find(t => t.itemid === this.state.selectedpicture.typeid) : {}} 
												handleChange={(e,newValue) => this.handleChangeTypeItem(e,newValue)} width='35%' fullWidth="true" />
												:
												<CustomTextField label="Type" value={this.state.types.find(t => t.itemid === this.state.selectedpicture.typeid) ? this.state.types.find(t => t.itemid === this.state.selectedpicture.typeid).label : ''} width='35%' fullWidth="true" />
											} */}
											<Grid container direction="row" xs={12}>
												<Grid item xs={8} sm={8}>
												{/* albumPicture.filename !== '' && albumPicture.filename !== this.state.selectedpicture.filename ? albumPicture.filename : */}
													<CustomTextField label="Fichier cible" value={this.state.selectedpicture.filename } required={true}
													create={create} handleChange={(e) => this.handleChangeGeneralItem(e, 'filename')} width='35%' fullWidth="true" />
												</Grid>
												{/* <Grid item xs={4} sm={4}>
													<div>
													<CustomCheckBox  label='Remplacer le fichier existant' labelPlacement="end" disabled ={!create || type==='create'} value={this.state.replaceFile} 
													onChange={()=>this.setState({replaceFile: !this.state.replaceFile})}/>
													</div>
												</Grid> */}
												{/* <Grid item xs={4} sm={4}>
													<Button className={classes.button} variant={'contained'} color={'primary'} disabled={!create} onClick={()=>this.openDropZoneDialog()}>
														<CloudUploadIcon style={{marginRight: '.5em'}} />Choisir un fichier
													</Button>
													
												</Grid> */}
												{(create) ? <Grid item xs={12} sm={12}>
													<DropZoneGlobal open={this.state.openAddFile} title={'IMPORT IMAGE'} albumPicture={albumPicture} OnAlbumPictureUpdate={OnAlbumPictureUpdate} OnLoadNewImage={OnLoadNewImage} 
														onClose={() => this.closeDropZoneDialog()}/>
												</Grid> : <div />}


											</Grid>
											<CustomTextField label="Titre de l'image" required ={true} value={ this.state.selectedpicture.title} width='35%' handleChange={(e) => this.handleChangeGeneralItem(e, 'titre')} fullWidth="true" create={create} />
											<Grid container direction="row" xs={12}>
												<Grid item xs={4} sm={4}>
													<CustomTextField label="Largeur" value={type === 'create' ? image.width :this.state.selectedpicture.width} width='35%' fullWidth="true" create={false} readOnly={true} />
												</Grid>
												<Grid item xs={4} sm={4}>
													<CustomTextField label="Hauteur" value={type === 'create' ? image.height :this.state.selectedpicture.height} width='35%' fullWidth="true" create={false} readOnly={true} />
												</Grid>
												<Grid item xs={4} sm={4}>
													<CustomTextField label="Pivot parent" value={this.state.selectedpicture.parentid} width='35%' fullWidth="true" create={false} readOnly={true} />
												</Grid>
												{ isImagePivot && <div style={{color :'darkorange', padding:'0px 0px 15px 10px'}}>Pour toute modification d'image pivot, merci d'utiliser l'assistant</div>}

											</Grid>
											{(this.state.selectedpicture.id !== "") ?
												<Tooltip title={'Cliquez pour consulter' 
													// &&
													// <ImageField label={this.state.selectedpicture.filename} source='filename' styleImg={this.state.selectedpicture.width > 1300 ? imgStyle :'initial'} basePath='/Albumspictures' record={this.state.selectedpicture} />
 												}  >

													<Link
														component="button"
														variant="body2"
														onClick={() => {
															window.open(Url.pictureAlbum(record.id, this.state.selectedpicture.filename), '_blank')
														}}
													>

														<ImageField label={this.state.selectedpicture.filename} source='filename' styleImg={'imageAlbumsLarge'} basePath='/Albumspictures' record={this.state.selectedpicture} />
													</Link>
												</Tooltip> : <div />}
										</Grid>
										<CustomDisplayDialog info='Alerte' to={Links.CompaniesalbumsShow(record.id)} handleClose={this.handleCloseErrorMessage} handleSave={this.handleRemoveImageClick} open={this.state.openAlertMessage}
											content={"Êtes-Vous sûr de vouloir supprimer cette image ?" } maxWidth="md" />
										<Grid item xs={4} direction="row" >
											<GestionFields classes={classes} record={this.state.selectedpicture} />
										</Grid>
									</Grid>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</ThemeProvider >
			</div>

		)
	}
}
const mapStateToProps = state => ({
	...state,
	albumPicture: state.reducer.albums.albumPicture !== undefined ? state.reducer.albums.albumPicture :null,
	image: state.reducer.albums.image !== undefined ? state.reducer.albums.image :null,
	isImagePivot: state.reducer.albums.isImagePivot

})

const mapDispatchToProps = ({
	OnAlbumPictureUpdate,
	OnLoadNewImage,
	SetIsImagePivot
})
const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)
export default enhance(AlbumImageFiche)
